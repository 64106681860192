import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCreative } from 'swiper';
import 'swiper/css';
import netZeroOneImg from '@partnersAssets/net-zero-movement/slide-1.jpg';
import netZeroTwoImg from '@partnersAssets/net-zero-movement/slide-1.jpg';
import netZeroThreeImg from '@partnersAssets/net-zero-movement/slide-1.jpg';
import './netZero.css';

export default function NetZeroCarousel() {
    const data = [
        {
            person: 'Rajiv Sisodia',
            desc: '“Agroforestry in Haryana has taken long strides. In every aspect, it’s a cheap, profitable, and sustainable alternative. Farmers are getting the most out of their lands and making more money by taking advantage of the growing wood-based industry in the state. The average productivity of seed-route agroforestry plantations in irrigated land is around 10-15 cubic meters per hectare. Things are in their initial phases but I am confident, farmers will benefit a lot from it in the long run.”',
            designation: 'Senior Indian Forest Service (IFS) Official',
            img: netZeroOneImg,
        },
        {
            person: 'Deepak Patidar',
            desc: '“When we first started no-tilling in 2019, it was a leap of faith to jump into the practice. It’s been more than 3 years and we have received numerous benefits in the form of reduced erosion, increased organic matter in the soil, and less fuel usage every single month. No-tilling is just one of the regenerative practices that we as a collective unit have agreed to practice on our farms and with the economic gains we have been receiving all this while, we will continue it for times to come.”',
            designation: 'Senior Indian Forest Service (IFS) Official',
            img: netZeroTwoImg,
        },
        {
            person: 'Motilal Arora',
            desc: '“When we first got introduced to the concept of nitrogen-fixing plants in Hoshiarpur back in 2017, it sounded like an over-promise. After initial hesitation within our community, slowly and steadily, we started adopting those new-age crops and were quick to realize that very easily the crops eliminated the usage of fertilizers helping us increase our income month by month. After a couple of months since we actively started using the crops, we realized that even if we stop using fertilizers, our output will remain the same and quality rich, the way we wanted. The practice is bearing us results and would recommend others to do the same as well.”',
            designation: 'Farmer from Punjab',
            img: netZeroThreeImg,
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className="bg-primary-light bg-element-blade-down pb-20">
            <section className="blade-padding-sm  sm:pb-32 pb-0 max-w-screen-xl mx-auto">
                <h2
                    className="h2 text-center max-w-screen-lg mx-auto px-5 sm:pb-28 pb-10 text-primary font-medium"
                    data-aos="fade-up"
                >
                    Hear it out from people who have joined us in this net-zero
                    movement
                </h2>

                <section
                    data-aos="fade-up"
                    className="carousel-wrapper bg-element-blade-upside overflow-hidden relative px-3 md:px-10 lg:px-3 mx-auto"
                >
                    <section className="h-[100%] bottom-auto md:flex justify-between gap-10 items-center p-5 hidden absolute z-10 left-0 right-0 ">
                        <button
                            className={` ${
                                activeIndex === 0 ? 'opacity-0' : ' opacity-100'
                            } transition-all duration-200 hover:stroke-yellow stroke-primary p-3 prev-btn rounded-full border-primary hover:border-yellow flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={` ${
                                activeIndex === data.length - 1
                                    ? 'opacity-0'
                                    : ''
                            } transition-all duration-200 hover:stroke-yellow stroke-primary next-btn p-3 rounded-full  border-primary hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </section>

                    <Swiper
                        autoHeight={true}
                        speed={1500}
                        navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            disabledClass: 'swiper-button-disabled',
                        }}
                        creativeEffect={{
                            limitProgress: 3,
                            prev: {
                                shadow: false,
                                translate: ['-100%', 0, 0],
                            },
                            next: {
                                translate: ['100%', 0, 0],
                            },
                        }}
                        onSlideChange={(swiper) =>
                            setActiveIndex(swiper.activeIndex)
                        }
                        modules={[Navigation, EffectCreative]}
                        spaceBetween={50}
                        effect={'creative'}
                        loop={false}
                        slidesPerView={1}
                    >
                        {data.map((elem, index) => {
                            return (
                                <SwiperSlide
                                    className="md:px-20 px-2"
                                    key={index}
                                >
                                    <div className="flex slide items-center lg:justify-around justify-center w-[100%] xl:p-14 lg:p-8 p-5 rounded-2xl border-2 border-primary ">
                                        <div className="w-[35%] h-full rounded-2xl overflow-hidden lg:block hidden ">
                                            <div className="object-cover">
                                                <img
                                                    src={elem.img}
                                                    alt=""
                                                    className="object-cover transition-all duration-500"
                                                />
                                            </div>
                                        </div>
                                        <div className="xl:w-[70%] w-full min-w-[18rem] md:min-h-fit  min-h-[35rem] flex flex-col justify-items-stretch items-start md:pl-10 pl-0 md:pr-14 pr-0">
                                            <h3 className="text-black h5">
                                                {elem.desc}
                                            </h3>
                                            <h2 className="text-primary h4 font-semibold leading-tight pt-4">
                                                {elem.person}
                                            </h2>
                                            <h3 className="text-primary h6 mt-1">
                                                {elem.designation}
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <div className="md:hidden flex justify-center mt-4 gap-10 items-center p-3 ">
                        <button
                            className={`transition-all duration-200 hover:stroke-yellow stroke-primary p-3  prev-btn rounded-full   hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={`transition-all duration-200 hover:stroke-yellow stroke-primary next-btn p-3 rounded-full  border-primary hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </div>
                </section>
            </section>
        </section>
    );
}

import React from 'react';
import './joinus.css';
import { NavButton } from '../../../modules/atoms';

export default function Joinus() {
    return (
        <section className="joinus-main-wrapper bg-primary-light ">
            <div className="joinus-wrapper container">
                <div className="color-primary" data-aos="fade-up">
                    <h2 className="h3 font-medium leading-tight">
                        Become a part of the select cohort of organizations as
                        they pave the way for a decarbonized world.
                    </h2>
                    <div>
                        <NavButton
                            link="for-businesses"
                            reverse
                            title="Know more"
                            className="h6 md:my-8 my-6"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

import React, { useEffect } from 'react';

const HubspotContactForm = (props) => {
    const { id } = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt && document.getElementById(id)) {
                setTimeout(function () {
                    window.hbspt.forms.create({
                        ...props,
                        target: '#' + id,
                    });
                }, 100);
            }
        });
    }, []);

    return (
        <div>
            <div id={id}></div>
        </div>
    );
};

export default HubspotContactForm;

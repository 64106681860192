import React, { useState } from 'react';
import './carousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCreative } from 'swiper';
import 'swiper/css';
import coinImage from '@aboutAssets/carousel/coin.png';
import varahaImage from '@aboutAssets/carousel/varaha.png';
import carouselBg from '@aboutAssets/carousel/carousel-bg.svg';
import { gsap } from 'gsap';
import { SwitchTransition, Transition } from 'react-transition-group';

export default function Carousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const data = [
        {
            desc: '‘Varaha’ is an avatar of the Hindu God Vishnu who saved the Earth by lifting it out of the cosmic ocean and restored it back to its place in the universe.',
            img: varahaImage,
        },
        {
            desc: 'Dating back to 1336-1646 in Southern India, the Vijayanagara Empire issued gold currency by the name of ‘Varaha’.',
            img: coinImage,
        },
    ];
    const exitAnim = (node) => {
        gsap.to(node, {
            duration: 0.3,
            yPercent: 10,
            opacity: 0,
            delay: 0.1,
        });
    };

    const enter = (node) => {
        gsap.fromTo(
            node,
            {
                opacity: 0,
                yPercent: 10,
            },
            {
                yPercent: 0,
                duration: 0.3,
                opacity: 1,
            }
        );
    };

    return (
        <section className=" blade-padding-sm ">
            <section className="bg-primary blade-padding-sm sm:pb-32 pb-14">
                <h2 className="h2 text-center p-10 sm:pb-28 pb-10 text-gradient">
                    What’s up with the name?
                </h2>

                <section className="carousel-wrapper overflow-hidden relative px-3 md:px-10 lg:px-3 md:w-11/12  lg:w-9/12 xl:w-9/12 2xl:w-8/12 max-w-screen-2xl mx-auto">
                    <div className="md:flex justify-between gap-10 items-center p-5 hidden absolute  bottom-auto h-[22rem] top-5 z-0 left-1/2 -translate-x-1/2">
                        <div className="mt-10 max-w-xl  mx-auto grid place-content-center">
                            <img src={carouselBg} alt="carousel bg" />
                        </div>
                    </div>
                    <section className="md:flex justify-between gap-10 items-center p-5 hidden absolute top-auto bottom-auto h-[26rem] z-10 left-0 right-0 ">
                        <button
                            className={`${
                                activeIndex === 0
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white p-3  prev-btn rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={` ${
                                activeIndex === 1
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white next-btn p-3 rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </section>
                    <Swiper
                        autoHeight={true}
                        speed={1500}
                        navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            disabledClass: 'swiper-button-disabled',
                        }}
                        creativeEffect={{
                            limitProgress: 3,
                            prev: {
                                shadow: false,
                                rotate: [0, 0, -45],
                                translate: ['-100%', 0, 0],
                            },
                            next: {
                                rotate: [0, 0, 45],
                                translate: ['100%', 0, 0],
                            },
                        }}
                        onSlideChange={(swiper) =>
                            setActiveIndex(swiper.activeIndex)
                        }
                        modules={[Navigation, EffectCreative]}
                        spaceBetween={50}
                        effect={'creative'}
                        loop={false}
                        slidesPerView={1}
                    >
                        {data.map((elem, index) => {
                            // let changeImageWidth =
                            //     activeIndex === 0 ? 'max-w-xs' : '';
                            // console.log('changeImageWidth', changeImageWidth);
                            return (
                                <SwiperSlide
                                    className="grid place-content-center place-items-center px-11"
                                    key={index}
                                >
                                    <div
                                        className={
                                            // activeIndex === 0
                                            //     ? 'min-h-full max-w-xs mx-auto mt-6'
                                            //     :
                                            'min-h-full max-w-md mx-auto'
                                        }
                                    >
                                        <img
                                            src={elem.img}
                                            alt=""
                                            className="transition-all  duration-500"
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <SwitchTransition>
                        <Transition
                            appear={true}
                            timeout={300}
                            in={true}
                            key={activeIndex}
                            onExit={exitAnim}
                            onEnter={enter}
                            mountOnEnter
                            unmountOnExit
                        >
                            <div className=" text-white text-gradient h4 max-w-screen-md mx-auto px-3 mt-10 md:mt-20 text-center min-h-[100px] md:min-h-[160px]">
                                <h5>{data[activeIndex].desc}</h5>
                            </div>
                        </Transition>
                    </SwitchTransition>

                    <div className="md:hidden flex justify-center mt-4 gap-10 items-center p-3 ">
                        <button
                            className={`${
                                activeIndex === 0
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white p-3  prev-btn rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={` ${
                                activeIndex === 1
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white next-btn p-3 rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </div>
                </section>
            </section>
        </section>
    );
}

import React from 'react';
import './timeline.css';
import first from '@aboutAssets/our-story/image(1).jpg';
import second from '@aboutAssets/our-story/image(2).jpg';
import third from '@aboutAssets/our-story/image(3).jpg';
import fourth from '@aboutAssets/our-story/image(4).jpg';
import fifth from '@aboutAssets/our-story/image(5).jpg';
import sixth from '@aboutAssets/our-story/image(6).jpg';
import seventh from '@aboutAssets/our-story/image(7).jpg';
import eight from '@aboutAssets/our-story/image(8).jpg';

import MobileView from './mobileView';

export default function Oursuite({ defaultTab = 0 }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    const tabs = [
        {
            title: '2021',
            heading: 'Preliminary groundwork laid',
            li1: 'Extensive research on reasons, mechanisms, and incentives that can motivate communities to implement climate-positive practices.',
            li2: 'Built a robust understanding of the climate/carbon credit markets and the existing issues that pertain related to them.',
            li3: 'Brainstormed and worked around a suite of processes and tools that would help us execute the implementation of carbon-removing activities.',
            li4: 'Came to terms with the role technology could play in protecting and restoring the tiny space rock called Earth.',
            src: first,
        },
        {
            title: 'Feb 2022 ',
            heading: 'Varaha incorporated',
            src: second,
        },
        {
            title: 'March 2022',
            heading: 'Full-fledged Operations began',
            li1: 'Operations began in several Indian states courtesy of a kickass team with professional experience of working across 5 continents, encompassing Entrepreneurs/Intrapreneurs, Remote Sensing Scientists, Climate Scientists, Data Scientists, Product Developers, Seasoned C-Suite Business Development Execs.',
            li4: 'Major sourcing done from-',
            li_1: 'IIM Ahmedabad',
            li_2: 'IIT Delhi',
            li_3: 'The Pennsylvania State University',
            li_4: 'Indian Institute of Sciences',
            li_5: 'Indian Institute of Remote Sensing',
            li_6: 'University of Jyväskylä, Finland',
            li_7: 'IIT Kharagpur',
            src: third,
        },
        {
            title: 'May 2022 ',
            heading: 'Raised a Pre-Seed Funding Round ',
            src: fourth,
        },
        {
            title: 'June 2022',
            heading: 'Signed MoUs and joined forces with several FPOs',
            heading2:
                'Signed an MoU with Maharana Pratap Horticulture University, Karnal, Government of Haryana',
            src: fifth,
        },
        {
            title: 'July 2022',
            heading: 'Product Proof-of-Concept finalized',
            src: sixth,
        },
        {
            title: 'August 2022',
            heading: 'Projects listed on Verra Standard',
            src: seventh,
        },
        {
            title: 'Sep 2022',
            heading:
                'Parallel expansion began in greater South Asia starting from Nepal',
            src: eight,
        },
    ];

    return (
        <section className="timeline-wrapper blade-padding-lg pb-32">
            <div className="w-[90%] mx-auto ">
                <h2 className="h1 text-gradient font-medium">Our Story</h2>

                <div className="timeline-card flex blade-margin-xs ">
                    <div
                        className="h-full w-full lg:flex hidden"
                        role="tablist"
                        aria-label="Top Footballers"
                    >
                        <div className="flex items-start w-[240px]">
                            <div className="w-[200px] h-full flex flex-col">
                                {tabs.map((tab, index) => {
                                    const active = activeTab === index;
                                    return (
                                        <button
                                            className={`${
                                                active ? 'active' : ''
                                            } h5 px-3 py-6  text-right text-white self-stretch h-full flex-1 w-[180px]`}
                                            key={index}
                                            role="tab"
                                            aria-selected={active}
                                            aria-controls={`panel-${index}`}
                                            id={`tab-${index}`}
                                            tabIndex={active ? 0 : -1}
                                            onClick={() => setActiveTab(index)}
                                        >
                                            {tab.title}
                                        </button>
                                    );
                                })}
                            </div>

                            <div
                                className={`timeline-status bg-primarylight h-full overflow-visible w-1 rounded-lg relative `}
                            >
                                <span
                                    className="bg-cyan rounded-lg transition-all duration-500 overflow-visible"
                                    style={{
                                        height: activeTab * 12.8 + 5 + '%',
                                    }}
                                ></span>
                            </div>
                        </div>

                        {tabs.map((tab, index) => (
                            <section
                                key={index}
                                id={`panel-${index}`}
                                // eslint-disable-next-line
                                role="tabpaneltimeline"
                                tabIndex="0"
                                aria-labelledby={`tab-${index}`}
                                hidden={activeTab !== index}
                            >
                                <div className="tab-panel-timeline   h-full bg-primary-light flex md:flex-row flex-col  justify-between items-center">
                                    <div className="bg-primary-light w-[50%] lg:py-20 py-10 xl:px-20 px-10">
                                        <h2 className="text-primary h3 font-medium">
                                            {tab.heading}
                                        </h2>

                                        <ul className="mt-5">
                                            <li className="h6 py-3 font-light">
                                                {tab.li1}
                                            </li>
                                            {tab.li2 ? (
                                                <li className="h6 py-3 font-light">
                                                    {tab.li2}
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {tab.li3 ? (
                                                <li className="h6 py-3 font-light">
                                                    {tab.li3}
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            <li className="h6 py-3 font-light">
                                                {tab.li4}
                                                {tab.li_1 ? (
                                                    <ul className="list-disc pl-6">
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_1}
                                                        </li>
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_2}
                                                        </li>
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_3}
                                                        </li>
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_4}
                                                        </li>
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_5}
                                                        </li>
                                                        <li className="h6 py-1 font-light">
                                                            {tab.li_6}
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    ''
                                                )}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="h-full w-[50%] md:border-none border-4 ">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={tab.src}
                                            alt="Story"
                                        />
                                    </div>
                                </div>
                            </section>
                        ))}
                    </div>

                    <div className="lg:hidden block ">
                        <MobileView data={tabs} />
                    </div>
                </div>
            </div>
        </section>
    );
}

import React, { useState } from 'react';
import './procressCarousel.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCreative } from 'swiper';
import 'swiper/css';
import processOneImg from '@businessAssets/process/process(1).png';
import processTwoImg from '@businessAssets/process/process(2).png';
import processThreeImg from '@businessAssets/process/process(3).png';
import processFourImg from '@businessAssets/process/process(4).png';
import processFiveImg from '@businessAssets/process/process(5).png';

export default function ProcessCarousel() {
    const data = [
        {
            heading: 'Smallholder Onboarding',
            desc: 'Farmers/Agroforestry/Forestry Practitioners and FPOs that follow carbon-neutral nature-based practices register themselves on the partner app. Datasets collected possess insights into the land and the green practices in place.',
            img: processOneImg,
        },
        {
            heading: 'Validation through Satellite Imagery/Models',
            desc: 'Key characteristics of a forest/agroforest/farm are validated and identified through remote sensing models that are trained using observations from Satellite Imagery, LiDAR Imaging, wide field plots network, and other touchpoints.',
            img: processTwoImg,
        },
        {
            heading: 'Tech-based Carbon Quantification',
            desc: 'Paving a way for technology-driven carbon markets, we employ an in-house developed proprietary Carbon Quantification Tool (CQT). It is based on a combination of biogeochemical models that takes localized parameters for accurate assessment of carbon credits, calculation of GHG reduction, carbon sequestration, and evaluating project scenarios. Furthermore, the models are calibrated using localized experiment data.',
            img: processThreeImg,
        },
        {
            heading: 'High-Quality Carbon Credit Generation',
            desc: 'The removed or sequestered GHGs are further converted into high-quality carbon credits via third-party validation, verification, and issuance through Verra and other credible registries.',
            img: processFourImg,
        },
        {
            heading: 'Purchase Credits',
            desc: 'Interested institutional buyers calculate their respective GHG emissions and voluntarily purchase carbon credits to offset them.',
            img: processFiveImg,
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className="bg-primary">
            <section className="blade-padding-sm sm:pb-32 pb-14 max-w-screen-xl mx-auto">
                <h2
                    className="h2 text-center p-10 sm:pb-28 pb-10 text-gradient "
                    data-aos="fade-up"
                >
                    How does our process work?
                </h2>

                <section
                    data-aos="fade-up"
                    className="carousel-wrapper bg-element-blade-upside overflow-hidden relative px-3 md:px-10 lg:px-3 mx-auto"
                >
                    <section className=" h-[90%] bottom-auto md:flex justify-between gap-10 items-center p-5 hidden absolute   z-10 left-0 right-0 ">
                        <button
                            className={` ${
                                activeIndex === 0 ? 'opacity-0' : ' opacity-100'
                            } hover:stroke-yellow stroke-white p-3  prev-btn rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={` ${
                                activeIndex === data.length - 1
                                    ? 'opacity-0 '
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white next-btn p-3 rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </section>

                    <Swiper
                        autoHeight={true}
                        speed={1500}
                        navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            disabledClass: 'swiper-button-disabled',
                        }}
                        creativeEffect={{
                            limitProgress: 3,
                            prev: {
                                shadow: false,
                                translate: ['-100%', 0, 0],
                            },
                            next: {
                                translate: ['100%', 0, 0],
                            },
                        }}
                        onSlideChange={(swiper) =>
                            setActiveIndex(swiper.activeIndex)
                        }
                        modules={[Navigation, EffectCreative]}
                        spaceBetween={50}
                        effect={'creative'}
                        loop={false}
                        slidesPerView={1}
                    >
                        {data.map((elem, index) => {
                            return (
                                <SwiperSlide
                                    className="grid place-content-center place-items-center md:px-11 px-3 "
                                    key={index}
                                >
                                    <div className="flex flex-wrap items-center lg:justify-around justify-center">
                                        <div className="flex-1 min-w-[300px] lg:pl-20">
                                            <div className="w-full md:rounded-[34px] rounded-[30px] overflow-hidden">
                                                <img
                                                    src={elem.img}
                                                    alt=""
                                                    className="w-full h-full transition-all duration-500"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-1 min-w-[300px] h-fit flex flex-col justify-items-stretch items-start md:pl-10 pl-0 md:pr-14 pr-4 md:mt-0 mt-5">
                                            <h2 className="text-white h3 leading-tight">
                                                {elem.heading}
                                            </h2>
                                            <h3 className="text-white h6 mt-4">
                                                {elem.desc}
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>

                    <div
                        className="dashed-line
           flex justify-around relative mt-20"
                    >
                        {data.map((item, index) => {
                            return (
                                <button
                                    key={index}
                                    className={`${
                                        activeIndex > index - 1
                                            ? 'bg-cyan'
                                            : 'bg-primary'
                                    } font-medium shadow-2xl w-12 h-12 text-xl rounded-full border-2 border-cyan border-dotted transition-all duration-300 z-10 text-white`}
                                >
                                    {index + 1}
                                </button>
                            );
                        })}
                    </div>

                    <div className="md:hidden flex justify-center mt-4 gap-10 items-center p-3 ">
                        <button
                            className={`${
                                activeIndex === 0
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white p-3  prev-btn rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 19.5L8.25 12l7.5-7.5"
                                />
                            </svg>
                        </button>
                        <button
                            className={` ${
                                activeIndex === 1
                                    ? 'opacity-30 hover:border-white hover:stroke-white'
                                    : 'opacity-100'
                            } hover:stroke-yellow stroke-white next-btn p-3 rounded-full border-2 border-white hover:border-yellow  flex items-center justify-center`}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="inherit"
                                className="w-6 h-6 stroke-inherit"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                            </svg>
                        </button>
                    </div>
                </section>
            </section>
        </section>
    );
}

import React, { useState } from 'react';
import './nature.css';
import projectThumbnailOne from '@homeAssets/project-card-one.jpg';
import projectThumbnailTwo from '@homeAssets/project-card-two.jpg';
import { ProjectCards } from '../../../modules/atoms';

import { Swiper, SwiperSlide } from 'swiper/react';
import {
    Navigation,
    EffectCreative,
    Pagination,
    Scrollbar,
    A11y,
} from 'swiper';
import 'swiper/css';
import { ProjectCarousel } from '../../../modules/molecules';

const Projects = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <section className="nature-section">
            <div className="nature-bgwrap md:h-screen h-[25rem] md:min-h-[50rem] min-h-fit ">
                <div className="container md:pt-52 pt-20">
                    <h2
                        data-aos="fade-up"
                        className="text-white leading-snug mr-8 h2 max-w-2xl font-medium drop-shadow-2xl "
                    >
                        Restoring nature & creating a legacy of projects we are
                        proud of
                    </h2>
                </div>
            </div>

            <div className="bg-primary-dark md:-mt-80 -mt-40 pb-40">
                <ProjectCarousel />
            </div>
        </section>
    );
};

export default Projects;

import React from 'react';
import './oursuite.css';
import imgOne from '@homeAssets/our-suite-end-to-end-project.png';
import imgTwo from '@homeAssets/our-suite-saas-based.png';
import imgThree from '@homeAssets/our-suite-access-hq-carbon-credits.png';
import MobileView from './mobileView';

export default function Oursuite({ defaultTab = 0 }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    const tabs = [
        {
            title: 'End-to-end Project Development',
            li1: 'Championing partnerships among landowners, investors, NGOs, businesses, and community groups to identify and support carbon removal through nature-based projects.',
            li2: 'Conserving, restoring, and managing natural ecosystems like forests, farmlands, agroforests, mangroves, grasslands, wetlands, etc.',
            li3: 'Ensuring immeasurable benefits like complementary synergies, a global bird’s eye view, and easy access to carbon markets.',
            src: imgOne,
        },
        {
            title: 'SaaS-based Digital(D) - MRV',
            li1: 'An end-to-end climate tech platform equipped with remote sensing, carbon modelling, and in-house proprietary Carbon Quantification Tool (CQT).',
            li2: 'Driving cheaper and more accurate partner enrollment, GHG quantification, measurement, reporting, and verification of projects and carbon credits at scale.',
            li3: 'Ensuring utmost efficiency and transparency at every step of carbon credit creation.',
            src: imgTwo,
        },
        {
            title: 'Access to high-quality Carbon Credits',
            li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
            li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
            li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
            src: imgThree,
        },
    ];

    return (
        <section className="oursuite-wrapper blade-padding-lg sm:pb-20 pb-10">
            <div className="container">
                <h2
                    className="h2 text-gradient text-center font-medium"
                    data-aos="fade-up"
                >
                    Our suite of offerings
                </h2>

                <div
                    className="tabbed-container blade-margin-xs sm:flex flex-col hidden"
                    role="tablist"
                    aria-label="Top Footballers"
                    data-aos="fade-up"
                >
                    <div className="tabs-wrapper">
                        {tabs.map((tab, index) => {
                            const active = activeTab === index;
                            return (
                                <button
                                    className={`${active ? 'activeTab' : ''
                                        } h5 px-1`}
                                    key={index}
                                    role="tab"
                                    aria-selected={active}
                                    aria-controls={`panel-${index}`}
                                    id={`tab-${index}`}
                                    tabIndex={active ? 0 : -1}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.title}
                                </button>
                            );
                        })}
                    </div>

                    {tabs.map((tab, index) => (
                        <section
                            key={index}
                            id={`panel-${index}`}
                            role="tabpanel"
                            tabIndex="0"
                            aria-labelledby={`tab-${index}`}
                            hidden={activeTab !== index}
                            data-aos="fade-up"
                        >
                            <div className="content-wrapper bg-primary ">
                                <div className="left-wrapper">
                                    <div className="img-wrapper">
                                        <img src={tab.src} alt="" />
                                    </div>
                                </div>

                                <div className="txt-wrapper bg-primary pl-16 ">
                                    <h2 className="color-yellow h4 ">
                                        {tab.title}
                                    </h2>

                                    <ul className="pr-16 relative track-oursuite">
                                        <li className="h6 text-white py-3 font-light">
                                            {tab.li1}
                                        </li>
                                        <li className="h6 text-white py-3 font-light">
                                            {tab.li2}
                                        </li>
                                        <li className="h6 text-white py-3 font-light">
                                            {tab.li3}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </section>
                    ))}
                </div>

                <div className="oursuite-mobile-view">
                    <MobileView data={tabs} />
                </div>
            </div>
        </section>
    );
}

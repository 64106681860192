import React from 'react';
import { CDN_URL } from '../../../../../config';
import arrowRight from '@ourProjectAssets/arrow_right.svg';

function ERWProcess({ title, rowOneImages, rowTwoImages, text }) {
    return (
        <section className="pt-[2rem] sm:pt-[12.5rem] bg-primarydark relative z-10 min-h-screen flex flex-col gap-16 lg:gap-28 pb-48">
            <h2 className="h2 text-gradient text-center font-medium">
                {title}
            </h2>

            <div className="flex flex-col gap-8 lg:gap-32 items-center lg:items-stretch">
                <div className="flex flex-col lg:flex-wrap lg:flex-row items-center justify-around  gap-8 lg:gap-0">
                    {rowOneImages.map((e, index) => (
                        <React.Fragment key={e + index}>
                            <img
                                src={arrowRight}
                                alt="arrowRight"
                                className={`hidden rounded-xl w-[3.5625rem] h-[4.8125rem] ${
                                    index === 0
                                        ? 'lg:block lg:opacity-0'
                                        : 'hidden'
                                } lg:relative lg:top-[1.4rem]`}
                            />
                            <div className="flex flex-col items-center font-medium gap-4 relative">
                                <h3 className="h4 text-yellow">{e.title}</h3>
                                <img
                                    src={
                                        CDN_URL +
                                        '/ourProjects/project_07/process/' +
                                        e.image
                                    }
                                    alt={e.image}
                                    className="h-[18.75rem] w-[23.5625rem] lg:h-[8rem] lg:w-[13rem] xl:h-[13.75rem] xl:w-[17.5625rem] 2xl:h-[17.75rem] 2xl:w-[23.5625rem] object-cover rounded-xl border-[0.5rem] border-[#567C7C] "
                                />
                            </div>

                            <img
                                src={arrowRight}
                                alt="arrowRight"
                                className={`rounded-xl w-[3.5625rem] h-[4.8125rem] transform rotate-90  lg:transform-none ${
                                    index === 2 ? 'hidden lg:block' : 'block'
                                } lg:relative lg:top-[1.4rem]`}
                            />
                        </React.Fragment>
                    ))}
                </div>
                <img
                    src={arrowRight}
                    alt="arrowRight"
                    className="rounded-xl w-[3.5625rem] h-[4.8125rem] transform rotate-90 block lg:hidden"
                />
                <div className="flex flex-col lg:flex-wrap lg:flex-row items-center justify-around  gap-8 lg:gap-0 ">
                    <img
                        src={arrowRight}
                        alt="arrowRight"
                        className={`rounded-xl w-[3.5625rem] h-[4.8125rem] hidden lg:block lg:relative lg:top-[1.4rem]`}
                    />
                    {rowTwoImages.map((e, index) => (
                        <React.Fragment key={e + index}>
                            <div
                                className={`flex flex-col items-center font-medium gap-4 relative`}
                            >
                                <h3 className="h4 text-yellow text-center">
                                    {e.title}
                                </h3>
                                <img
                                    src={
                                        CDN_URL +
                                        '/ourProjects/project_07/process/' +
                                        e.image
                                    }
                                    alt={e.image}
                                    className="h-[18.75rem] w-[23.5625rem] lg:h-[8rem] lg:w-[13rem] xl:h-[13.75rem] xl:w-[17.5625rem] 2xl:h-[17.75rem] 2xl:w-[23.5625rem]  object-fill border-[0.5rem]  border-[#567C7C] rounded-xl"
                                />
                            </div>

                            <img
                                src={arrowRight}
                                alt="arrowRight"
                                className={`rounded-xl w-[3.5625rem] h-[4.8125rem] transform rotate-90  lg:transform-none ${
                                    index < 2 ? 'opacity-100' : 'opacity-0'
                                } lg:relative lg:top-[1.4rem]`}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ERWProcess;

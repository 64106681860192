import { useEffect } from 'react';
import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    GeographicalFocus,
} from '../reusables';
import { nepalSdg } from '../../config/config';
import community from '@ourProjectAssets/stats/community.svg';
import farmer from '@ourProjectAssets/stats/farmer.svg';
import acre from '@ourProjectAssets/stats/acre.svg';
import nepalFlag from '@ourProjectAssets/flags/nepal.png';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function AgroNepal() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        Improving The Livelihood Of <br />
                        Farmers Via Carbon Finance Project In Nepal
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={nepalFlag}
                            alt="nepal_flag"
                            className="w-12 h-6 relative left-4"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:bottom-1">
                            Nepal  |  44 Districts  |  35,000 Hectares
                        </h2>
                    </div>
                }
                thumbnail={CDN_URL + '/ourProjects/project_02/02.png'}
                mediaType="image"
                pb="20"
                reverseHeading={true}
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'In Nepal, a project spanning seven districts (Ilam, Morang, Jhapa, Sunsari, Sarlahi, and Dhanusha) is being implemented to promote agroforestry and planting native fruit and nut trees like Apple, Guava, Lemon, Litchi, Mango, Kadam, and Betel Nut.'
                }
                paragraph2={
                    'This initiative, focusing on smallholder farmers, emphasizes the swift adoption of scientific agroforestry practices. All credits generated from this project are classified as removal credits.'
                }
                paragraph3={
                    ' Starting in 2020, which is considered the baseline year, the project initially covered 1,000 hectares and is projected to expand to 35,000 hectares by 2030, maintaining this area until 2039. The project has a 20-year crediting period, with the possibility of renewal.'
                }
                id={4046}
                projectType={
                    <>
                        Agriculture Forestry and <br />
                        Other Land Use (ARR)
                    </>
                }
                region={'Nepal'}
                methodology={'AR-ACM0003'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/nepal.png',
                    altText: 'nepal_map',
                }}
                region={'Nepal'}
                imageClassName="w-[60%] h-5/6 lg:ml-42"
            />
            <ImplementationPartners
                title={'Implementation Partners'}
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/muktinath.png',
                        altText: 'muktinath.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        title: <>Muktinath Krishi Company</>,
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/sahara.png',
                        altText: 'sahara.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        title: <>Sahara Nepal</>,
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/yac.png',
                        altText: 'yac.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        title: <>Youth Acting for Change Nepal</>,
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/sabal.png',
                        altText: 'sabal.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        title: <>Sabal Nepal</>,
                    },
                    {
                        src:
                            CDN_URL + '/ourProjects/partners/nepal_partner.png',
                        altText: 'nepal_partner.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        title: <>Nepal Farmer Advisory Council</>,
                    },
                ]}
            />
            <VarahaImpact
                carousal={true}
                contents={nepalSdg}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 10 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={[
                    '000',
                    '001',
                    '002',
                    '003',
                    '004',
                    '005',
                    '006',
                    '007',
                    '008',
                    '009',
                    '010',
                    '011',
                    '012',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_02/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + '.png',
                }))}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: farmer,
                        altText: 'farmer.svg',
                        start: 7500,
                        end: 8500,
                        unit: '',
                        description: 'Onboarded Farmers',
                        title: '',
                    },
                    {
                        imageUrl: acre,
                        altText: 'community.svg',
                        start: 14314,
                        end: 15314,
                        unit: '',
                        description: 'Acres Onboarded',
                        title: '',
                    },
                    {
                        imageUrl: community,
                        altText: 'sustainablePractice.svg',
                        start: 243000,
                        end: 244000,
                        unit: '',
                        description: 'tonnes of carbon sequestered',
                    },
                ]}
            />
        </div>
    );
}

export default AgroNepal;

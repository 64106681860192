import { useEffect } from 'react';
import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    GeographicalFocus,
} from '../reusables';
import community from '@ourProjectAssets/stats/community.svg';
import banglaFlag from '@ourProjectAssets/flags/bangladesh.png';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
import farmer from '@ourProjectAssets/stats/farmer.svg';
import acre from '@ourProjectAssets/stats/acre.svg';

function AgroBangladesh() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        Fostering Farmer Livelihoods & Carbon Credits via
                        Horticultural Plantations in Bangladesh
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={banglaFlag}
                            alt="bangla_flag"
                            className="w-12 h-6 relative top-[5px]"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:bottom-1">
                            Bangladesh |  3 Districts  |  35,000 Hectares
                        </h2>
                    </div>
                }
                // videoLink={CDN_URL + '/ourprojects.mp4'}
                pb="20"
                reverseHeading={true}
                thumbnail={CDN_URL + '/ourProjects/project_03/03.png'}
                mediaType="image"
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'In Bangladesh, a significant ARR project is being implemented across three districts: Chapainawabganj, Nogaon, and Rajshahi. The initial phase of the project, which began in 2020, focuses on the cultivation of Mango and Guava, with plans to diversify the species over time. '
                }
                paragraph2={
                    'This initiative is primarily generating removal credits, contributing to environmental sustainability. The project commenced on a 2,000-hectare land base, which is expected to expand to 35,000 hectares by 2028 and will be maintained at this scale until 2040.'
                }
                paragraph3={
                    'The baseline year for this project is established as 2020, marking the onset of interventions. It features a 20-year crediting period, with the option for renewal, fostering long-term environmental and economic benefits.'
                }
                id={4456}
                projectType={
                    <>
                        Agriculture Forestry and <br />
                        Other Land Use (ARR)
                    </>
                }
                region={'Bangladesh'}
                methodology={'AR-ACM0003'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/Bangladesh.png',
                    altText: 'bangladesh_map',
                }}
                region={'Bangladesh'}
                imageClassName="w-[35%] h-5/6"
            />
            <ImplementationPartners
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/syngenta.png',
                        altText: 'syngenta.png',
                        backgroundColor: 'white',
                        widthStyle: 'w-[49rem] h-[18rem] p-[4rem]',
                        title: (
                            <>Syngenta Foundation For Sustainable Agriculture</>
                        ),
                    },
                ]}
                title={'Implementation Partners'}
                imageClassName="w-[45%] h-5/6 lg:ml-40"
            />
            <VarahaImpact
                carousal={false}
                sdgs={[
                    '/no_poverty.png',
                    '/hunger.png',
                    '/education.png',
                    '/gender.png',
                    '/economy.png',
                    '/inequalities.png',
                    '/responsible.png',
                    '/climate.png',
                    '/land.png',
                    '/partnerships.png',
                ].map((e) => CDN_URL + '/ourProjects' + '/sdg' + e)}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 10 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={['001', '002', '003', '004', '005', '006', '008'].map(
                    (e) => ({
                        imageUrl:
                            CDN_URL +
                            '/ourProjects/project_03/visual_diary/' +
                            e +
                            '.jpg',
                        altText: e + '.jpg',
                    })
                )}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: farmer,
                        altText: 'farmer.svg',
                        start: 2900,
                        end: 3900,
                        unit: '',
                        description: 'Onboarded Farmers',
                        title: '',
                    },
                    {
                        imageUrl: acre,
                        altText: 'community.svg',
                        start: 14067,
                        end: 15067,
                        unit: '',
                        description: 'Acres Onboarded',
                        title: '',
                    },
                    {
                        imageUrl: community,
                        altText: 'sustainablePractice.svg',
                        start: 179000,
                        end: 180000,
                        unit: '',
                        description: 'tonnes of carbon sequestered',
                    },
                ]}
            />
        </div>
    );
}

export default AgroBangladesh;

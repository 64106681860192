function GeographicalFocus({ image, region, imageClassName }) {
    return (
        <section className="bg-primarydark py-[3rem] flex flex-col gap-7 xl:gap-14 items-center relative pb-12">
            <div className="flex flex-col items-center">
                <h2 className="h2 text-gradient">Geographical Focus</h2>
                <h3 className="h4 text-gradient">{region}</h3>
            </div>
            <img
                src={image.imageUrl}
                alt={image.altText}
                className={imageClassName}
            />
        </section>
    );
}

export default GeographicalFocus;

import { useEffect } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import routes from './routes';

const RenderRoutes = () => {
    let routeTree = useRoutes(routes());
    return routeTree;
};

function App() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BrowserRouter>
                <RenderRoutes />
            </BrowserRouter>
        </>
    );
}

export default App;

import React from 'react';
import { IoArrowDownCircleOutline } from 'react-icons/io5';
import agri from '@partnersAssets/practices-agriculture.png';
import agro from '@partnersAssets/practices-agroforestry.jpg';
import forestry from '@partnersAssets/practices-forestry.jpg';

import './practices.css';

export default function Practices() {
    const Dropdown = ({ title, desc, idx }) => {
        return (
            <div className="flex py-2 lg:mt-5 border-b-[1px] border-[#AAAAAA] border-opacity-50">
                <label className="data-card cursor-pointer w-full">
                    <input
                        type="radio"
                        defaultChecked={idx === 0}
                        autoFocus={idx === 0}
                        name="practices-option"
                    />
                    <div className="wrapper">
                        <div className="flex justify-between lg:py-4">
                            <h5 className="h5 text-white font-light space-x-1">
                                {title}
                            </h5>
                            <IoArrowDownCircleOutline className="arrow h3 text-white" />
                        </div>
                        <div className="desc">
                            <span className="h6 fw-400 text-gray font-thin">
                                {desc}
                            </span>
                        </div>
                    </div>
                </label>
            </div>
        );
    };

    const agriData = [
        {
            title: 'Direct Seeding Rice',
            desc: `Sequesters an equivalent of 1 tonne CO\u2082/hectare.`,
        },
        {
            title: 'Zero Till Farming',
            desc: 'Sequesters an equivalent of 1-1.6 tonne CO\u2082/hectare.',
        },
        {
            title: 'Nitrogen-fixing Crops',
            desc: 'Usage in summer sequesters an equivalent of 0.5 tonnes CO\u2082/hectare.',
        },
        {
            title: 'Abstaining from burning crop residue',
            desc: 'Sequesters an equivalent of 1-1.6 tonnes CO\u2082/hectare.',
        },
    ];

    const agroData = [
        {
            title: 'Timber Plantation',
            desc: 'Sequesters CO\u2082',
        },
        {
            title: 'Fruit Tree Plantation',
            desc: 'Sequesters CO\u2082',
        },
    ];
    const forestryData = [
        {
            title: 'Providing Alternate Livelihood',
            desc: 'Practices that prevent deforestation & ensure afforestation',
        },
        {
            title: 'Reducing Fuel wood & NTFP collection',
            desc: 'Practices preserving soil organic carbon',
        },
        {
            title: 'Forest Fencing',
            desc: 'Forest conservation activities',
        },
        {
            title: 'Reducing Forest Fires & Community Training',
            desc: 'Practices that keep communities in accordance with activities that prevent forest fires & soil erosion',
        },
    ];

    return (
        <section className="practices-blade-wrapper bg-primary blade-padding-sm pb-28">
            <div className="flex items-center">
                <div className="container xxl:px-32 xl:px-16">
                    <h2 className="h2 text-gradient text-center leading-normal font-medium">
                        What do we look for in terms of practices?
                    </h2>

                    <div className="flex justify-between lg:flex-row flex-col-reverse lg:gap-16 gap-5 blade-padding-lg ">
                        <div className="flex-1">
                            <h3 className="h3 text-white">
                                Regenerative Agriculture
                            </h3>
                            <div className="flex flex-col lg:max-w-xl">
                                {agriData.map((elem, index) => {
                                    const { title = '', desc = '' } = elem;
                                    return (
                                        <Dropdown
                                            key={index}
                                            idx={index}
                                            title={title}
                                            desc={desc}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="h-full flex items-start lg:justify-start justify-center">
                                <div className="rounded-3xl md:rounded-3xl overflow-hidden border-4 md:border-[10px]  border-opacity-50 border-slate-500 shadow-2xl">
                                    <img
                                        src={agri}
                                        className="w-full h-full object-cover"
                                        alt="Agriculture"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between lg:flex-row flex-col lg:gap-16 gap-5 blade-padding-lg">
                        <div className="flex-1">
                            <div className="h-full flex items-center justify-start">
                                <div className="rounded-3xl md:rounded-3xl overflow-hidden border-4 md:border-[10px] border-opacity-50 border-slate-500 shadow-2xl">
                                    <img
                                        src={agro}
                                        className="w-full h-full object-cover"
                                        alt="Agroforestry"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1">
                            <h3 className="h3 text-white">
                                Afforestation, Reforestation and Revegetation
                            </h3>
                            <div className="flex flex-col max-w-xl">
                                {agroData.map((elem, index) => {
                                    const { title = '', desc = '' } = elem;
                                    return (
                                        <Dropdown
                                            key={index}
                                            idx={index}
                                            title={title}
                                            desc={desc}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex justify-between lg:flex-row flex-col-reverse lg:gap-16 gap-5 blade-padding-lg">
                        <div className="flex-1">
                            <h3 className="h3 text-white">Forestry</h3>
                            <div className="flex flex-col max-w-xl">
                                {forestryData.map((elem, index) => {
                                    const { title = '', desc = '' } = elem;
                                    return (
                                        <Dropdown
                                            key={index}
                                            idx={index}
                                            title={title}
                                            desc={desc}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="h-full flex items-start  lg:justify-start justify-center">
                                <div className="rounded-xl md:rounded-3xl overflow-hidden border-4 md:border-[10px] border-opacity-50 border-slate-500 shadow-2xl">
                                    <img
                                        src={forestry}
                                        className="w-full h-full object-cover"
                                        alt="forestry"
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

import React from 'react';
import './mobileModal.css';
import { MdOutlineCancel } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

const Navigation = [
    {
        link: '',
        text: 'Home',
    },
    {
        link: 'about-us',
        text: 'About us',
    },
    {
        link: 'careers',
        text: 'Careers',
    },
    {
        link: 'ourProjects',
        text: 'Projects',
    },
    {
        link: 'our-tech',
        text: 'Our Tech',
    },
    {
        link: 'for-businesses',
        text: 'For Businesses',
    },
    {
        link: 'for-partners',
        text: 'For Partners',
    },
    {
        link: 'https://varaha-privacy-policy.s3.ap-south-1.amazonaws.com/index2.html',
        text: 'Terms of use',
        target: '_blank',
        rel: 'noopener noreferrer',
    },
    {
        link: 'disclaimer',
        text: 'Disclaimer',
    },
];

export default function MobileModal({ className, setIsOpen, onClick }) {
    return (
        <div className={`${className} mobile-modal`}>
            <div className="container mobile-nav mobile-header-container">
                <MdOutlineCancel
                    className="cancel-icon"
                    onClick={() => setIsOpen(false)}
                />
            </div>

            <div className="mobile-list">
                {Navigation.map((item, key) =>
                    item.target && item.rel ? (
                        <NavLink
                            key={key}
                            className="navLink py-3 px-3 block"
                            onClick={onClick}
                            to={item.link}
                            end
                            target={item.target}
                            rel={item.rel}
                        >
                            {item.text}
                        </NavLink>
                    ) : (
                        <NavLink
                            key={key}
                            className="navLink py-3 px-3 block"
                            onClick={onClick}
                            to={item.link}
                            end
                        >
                            {item.text}
                        </NavLink>
                    )
                )}
            </div>
        </div>
    );
}

import React from 'react';
import './cardFlex.css';

export default function CardFlex({
    heading,
    heading_1,
    subhead_1,
    subhead_2,
    subhead_3,
    image,
    reverse,
    number,
    table,
    lists,
    ul2,
}) {
    return (
        <div>
            {number ? (
                <div className=" mt-32">
                    <h2 className="bg-primary mx-auto font-souvenir h4 font-light text-white h-14 w-14 rounded-lg flex items-center justify-center">
                        {number}
                    </h2>
                </div>
            ) : (
                ''
            )}
            <div
                className={`${
                    reverse ? 'flex flex-row-reverse' : 'flex'
                } flex-wrap justify-center xl:pb-20 xl:pt-10 `}
            >
                <div
                    className={`${
                        reverse
                            ? 'xl:pl-20 lg:pl-10 pr-0 bg-element-leaves-reverse'
                            : 'xl:pr-20 pr-0 bg-element-leaves'
                    }  sm:w-[50%] lg:py-20 py-5`}
                >
                    <div
                        className="cardFlex-img-wrapper lg:p-4 p-3 shadow-xl rounded-xl "
                        data-aos="fade-up"
                    >
                        <img src={image} className="rounded-xl" alt="" />
                    </div>
                </div>
                <div
                    className={`${
                        reverse ? 'xl:pr-20 pr-5' : 'xl:pl-20 lg:pl-10'
                    } lg:w-[50%] md:py-20 py-5 flex justify-center flex-col `}
                >
                    <h4
                        data-aos="fade-up"
                        className="h4 leading-normal text-gradient-dark pr-12 font-semibold"
                    >
                        {heading}
                    </h4>
                    <h5
                        data-aos="fade-up"
                        className="h6 leading-normal pr-6 mt-2"
                    >
                        {subhead_1}
                    </h5>
                    <h5
                        data-aos="fade-up"
                        className="h6 leading-normal pr-6 mt-2"
                    >
                        {subhead_2}
                    </h5>
                    <h5
                        data-aos="fade-up"
                        className="h6 leading-normal pr-6 mt-2"
                    >
                        {subhead_3}
                    </h5>

                    <div className="">
                        {lists ? (
                            <div className="flex justify-between">
                                {lists.map((item, index) => (
                                    <div key={index}>
                                        <ul className="font-bold">
                                            {item.ul}
                                            {item.list.map((item, ind) => (
                                                <li key={ind} className="mt-2">
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

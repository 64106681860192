import React from 'react';
import './stats.css';
import acres from '@homeAssets/stats/acres.svg';
import co2 from '@homeAssets/stats/co2.svg';
import waterSaved from '@homeAssets/stats/water-saved.svg';
import farmer from '@ourProjectAssets/stats/farmer.svg';

import CountUp from 'react-countup';

export default function Stats() {
    return (
        <>
            <section className=" bg-[#001A18] stats-wrapper">
                <div className="blade-padding-sm">
                    <h2 className="h2 text-gradient text-center lg:w-[50%] w-11/12 2xl:w-[35%] mx-auto leading-tight py-5">
                        What sustainable impact means to us?
                    </h2>
                    <div className="blade-padding-xs px-3">
                        <div className="flex flex-wrap px-3 py-14 max-w-md md:flex-row flex-col items-center  justify-around md:max-w-screen-2xl mx-auto  md:w-11/12  cards-wrapper rounded-3xl gap-12 sm:gap-0">
                            <div className="  grow-0 mx-auto  shrink-0 grid  justify-center text-center sm:text-left sm:justify-start">
                                <div className="py-5 md:py-10  px-2 lg:px-0 text-center">
                                    <img
                                        className="h-20 object-contain"
                                        src={farmer}
                                        alt=""
                                    />
                                    <div className=" pt-1 md:pt-4">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={79000}
                                            end={80000}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2 text-center">
                                        Farmers Onboarded
                                    </h6>
                                </div>
                            </div>
                            <div className="grow-0 mx-auto  shrink-0 grid  justify-center text-center sm:text-left sm:justify-start">
                                <div className="py-5 md:py-10  px-2 lg:px-0 text-center">
                                    <img
                                        className="h-20 object-contain"
                                        src={acres}
                                        alt=""
                                    />
                                    <div className=" pt-1 md:pt-4">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={699000}
                                            end={700000}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2 text-center">
                                        Acres covered
                                    </h6>
                                </div>
                            </div>
                            <div className=" grow-0 shrink-[2]  mx-auto card-divide-left  grid sm:justify-start justify-center text-center sm:text-left">
                                <div className=" py-5 md:py-10  px-2 lg:px-0">
                                    <img
                                        className="h-20 object-contain"
                                        src={co2}
                                        alt=""
                                    />
                                    <div className="pt-1 md:pt-4  text-center">
                                        <CountUp
                                            className="h5 text-[#FFFFFF] "
                                            start={1699000}
                                            end={1700000}
                                            suffix={' +'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500  pt-2 md:pt-2  text-center">
                                        Tonnes of CO
                                        <sub>2</sub> sequestered
                                    </h6>
                                </div>
                            </div>
                            <div className="grow-0 shrink mx-auto grid sm:justify-start justify-center  text-center sm:text-left">
                                <div className=" py-5 md:py-10  px-2 lg:px-0">
                                    <img
                                        className="h-20 object-contain"
                                        src={waterSaved}
                                        alt=""
                                    />
                                    <div className="pt-1 md:pt-4 text-center">
                                        <CountUp
                                            className="h5 text-[#FFFFFF]  "
                                            start={0}
                                            end={750}
                                            suffix={' Million litres'}
                                            duration={6}
                                            separator=","
                                        />
                                    </div>
                                    <h6 className="h5 text-[#F9AC00] fw-500   pt-2 md:pt-2  text-center">
                                        Litres of water saved
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="blade-padding-lg mt-20"></div>
                </div>
            </section>
        </>
    );
}

/*
  
  


<section className="bg-[#001A18] stats-wrapper ">
        <div className=" ">
          <h2 className="h2 text-gradient text-center lg:w-[50%] w-11/12 2xl:w-[35%] mx-auto leading-tight py-5">
            What sustainable impact means to us?
          </h2>
          <div className="stats-container">
            <div className=" py-6">
              <img className="w-20 " src={acres} alt="" />
              <h5 className="h5 text-[#F9AC00] font-semibold text-center">
                Acres covered
              </h5>
              <div className="pt-2 pb-7 ">
                <CountUp
                  className="h5 text-[#FFFFFF] "
                  end={132000}
                  start={131000}
                  suffix={' +'}
                  duration={10}
                />
              </div>
            </div>
            <span className="stats-border"></span>
            <div className=" py-6 ">
              <img className="w-12 " src={tons} alt="" />
              <h5 className="h5 text-[#F9AC00] font-semibold text-center">
                Tonnes of CO2 sequestered
              </h5>
              <div className="pt-2 pb-7 ">
                <CountUp
                  className="h5 text-[#FFFFFF] "
                  start={349000}
                  end={350000}
                  suffix={' +'}
                  duration={10}
                />
              </div>
            </div>
            <span className="stats-border"></span>
            <div className=" py-6">
              <img className="w-12 " src={water} alt="" />
              <h5 className="h5 text-[#F9AC00] font-semibold text-center">
                Liters of water saved
              </h5>
              <div className="pt-2 pb-7 ">
                <CountUp
                  className="h5 text-[#FFFFFF]  "
                  start={153913}
                  end={154913}
                  suffix={' Liters'}
                  duration={10}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      
// */

//   <CountUp
//                                             className="h5 text-[#FFFFFF] "
//                                             start={79000}
//                                             end={80000}
//                                             suffix={' +'}
//                                             duration={6}
//                                             separator=","
//                                         />
//  <CountUp
//                                             className="h5 text-[#FFFFFF] "
//                                             start={699000}
//                                             end={700000}
//                                             suffix={' +'}
//                                             duration={6}
//                                             separator=","
//                                         />
//  <CountUp
//                                             className="h5 text-[#FFFFFF] "
//                                             start={1699000}
//                                             end={1700000}
//                                             suffix={' +'}
//                                             duration={6}
//                                             separator=","
//                                         />
//   <CountUp
//                                             className="h5 text-[#FFFFFF]  "
//                                             start={749000}
//                                             end={750000}
//                                             suffix={' Million litres'}
//                                             duration={6}
//                                             separator=","
//                                         />

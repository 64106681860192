import React, { useEffect } from 'react';
import Internship from './internship';
import Service from './service';
import Define from './whatDefine';
import Perks from './perks';
import { Banner } from '../../modules/molecules';
import thumbnail from '@thumbnailsAssets/careers.jpg';
import { CDN_URL } from '../../config';
import { useLocation } from 'react-router-dom';
export default function CareerScreen() {
    const location = useLocation();
    useEffect(() => {
        if (location.state?.scrollToElementId) {
            const section = document.getElementById(
                location.state.scrollToElementId
            );
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            return;
        }
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="career-wrapper">
            <Banner
                heading="A mission you care about"
                subHeading="We welcome your expertise at the service of the planet"
                videoLink={CDN_URL + '/careers.m4v'}
                navhashLink="#joinus"
                thumbnail={thumbnail}
            />
            <Define />
            <Perks />
            <Service />
            <Internship />
        </section>
    );
}

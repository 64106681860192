import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { ProjectCards } from '../../atoms';
import { CDN_URL } from '../../../config';

const project1 = [
    {
        lh: 'Tenure',
        li: ' 2019 - 2039',
    },
    {
        lh: 'Area under Focus',
        li: 'Indo-Gangetic Plains',
    },
    {
        lh: 'Area of Coverage',
        li: '2 Million Hectares',
    },
    {
        lh: 'Emission Reduction',
        li: '7.0 Million Tonnes CO\u2082 eq./annum',
    },
];

const project2 = [
    {
        lh: '',
        li: '',
    },
    {
        lh: '',
        li: '',
    },
    {
        lh: '',
        li: '',
    },
    {
        lh: '',
        li: '',
    },
];

export default function ProjectCarousel({ darkArrows }) {
    return (
        <>
            <div className="md:pl-[10%] md:w-full w-[90%] mx-auto overflow-hidden projects-swiper">
                <Swiper
                    autoHeight={true}
                    speed={500}
                    spaceBetween={50}
                    effect={'creative'}
                    loop={false}
                    slidesPerView="auto"
                    keyboard={true}
                    navigation={{
                        nextEl: '.next-btn',
                        prevEl: '.prev-btn',
                        disabledClass: 'swiper-button-disabled',
                    }}
                    pagination={{
                        bulletClass: 'p-2 bg-red',
                    }}
                    className="hidden md:block "
                >
                    <SwiperSlide className="swiperSlideCls">
                        <ProjectCards
                            listData={project1}
                            thumbnail={
                                CDN_URL +
                                '/ourProjects/project_01/01_secondary.png'
                            }
                            heading="Indo-Gangetic Plains Regenerative Agriculture"
                            para="The project introduces regenerative agriculture in the Indo-Gangetic Plains of India to cut greenhouse gas emissions, rejuvenate soil, and increase crop productivity. Replacing traditional practices with Direct Seeding of Rice, Reduced Tillage, and Crop Residue Management fosters a substantial carbon sink via soil carbon removal."
                            to="/ourProjects/indo-gangetic"
                        />
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlideCls">
                        <ProjectCards
                            listData={project2}
                            thumbnail={
                                CDN_URL +
                                '/ourProjects/project_08/08_secondary.png'
                            }
                            heading="High-Tech Biochar Project"
                            para="The project is based in a Maize seeds processing facility that utilises waste biomass from corn cobs and gasifies it for generating syngas which is utilized to provide heat for the drying process. This is a high-tech facility undergoing accreditation from Puro.Earth. The project will remove more than 1,000 tonnes of CO₂, each year while it is operational through the production of biochar. The pulverized biochar is provided to farmers close to the facility free of cost to be used as a soil amendment and for the improvement of soil health."
                            to="ourProjects/puro-industrial-glasifier-telangana"
                        />
                    </SwiperSlide>
                </Swiper>

                <div className="md:hidden flex flex-col gap-5">
                    <ProjectCards
                        listData={project1}
                        thumbnail={
                            CDN_URL + '/ourProjects/project_01/01_secondary.png'
                        }
                        heading="Indo-Gangetic Plains Regenerative Agriculture"
                        para="The project introduces regenerative agriculture in the Indo-Gangetic Plains of India to cut greenhouse gas emissions, rejuvenate soil, and increase crop productivity. Replacing traditional practices with Direct Seeding of Rice, Reduced Tillage, and Crop Residue Management fosters a substantial carbon sink via soil carbon removal."
                        to="/ourProjects/indo-gangetic"
                    />
                    <ProjectCards
                        listData={project2}
                        thumbnail={
                            CDN_URL + '/ourProjects/project_08/08_secondary.png'
                        }
                        heading="High-Tech Biochar Project"
                        para="The project is based in a Maize seeds processing facility that utilises waste biomass from corn cobs and gasifies it for generating syngas which is utilized to provide heat for the drying process. This is a high-tech facility undergoing accreditation from Puro.Earth. The project will remove more than 1,000 tonnes of CO₂, each year while it is operational through the production of biochar. The pulverized biochar is provided to farmers close to the facility free of cost to be used as a soil amendment and for the improvement of soil health."
                        to="ourProjects/puro-industrial-glasifier-telangana"
                    />
                </div>
            </div>

            {/* <section className="hidden md:flex container py-5 gap-4 justify-end items-center top-auto bottom-auto z-10 left-0 right-0 ">
                <button
                    className={`${
                        activeIndex === 0
                            ? 'opacity-30 hover:border-white hover:stroke-white'
                            : 'opacity-80'
                    } 
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 prev-btn rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="inherit"
                        className="w-6 h-6 stroke-inherit"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                </button>
                <button
                    className={`${
                        activeIndex === 1
                            ? 'opacity-30 hover:border-white hover:stroke-white'
                            : 'opacity-80'
                    } 
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 next-btn rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="inherit"
                        className="w-6 h-6 stroke-inherit"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                </button>
            </section> */}
        </>
    );
}

import React from 'react';
import './onBoardingCards.css';

const Define = () => {
    return (
        <>
            <section className="blade-padding-sm bg-cream pb-10">
                <div className="">
                    <h2 className="h2 text-center color-primary font-medium max-w-screen-lg mx-auto px-2">
                        Let’s boil it down to how a partner is onboarded with us
                    </h2>
                    <div className="onboarding-cards grid-cols-4 gap-4">
                        <div className="cards">
                            <span className="font-souvenir border-[1px] p-3 bg-primary-dark rounded-xl">
                                01
                            </span>
                            <h5>Partner Application</h5>
                            <p>Register your land with a simple form.</p>
                        </div>
                        <div className="cards">
                            <span className="font-souvenir border-[1px] p-3 bg-primary-dark rounded-xl">
                                02
                            </span>
                            <h5>Partner Assessment</h5>
                            <p>Remote sensing-based validation of practices.</p>
                        </div>
                        <div className="cards">
                            <span className="font-souvenir border-[1px] p-3 bg-primary-dark rounded-xl">
                                03
                            </span>
                            <h5>Audit</h5>
                            <p>
                                Auditor visit for cross-verification of the
                                data.
                            </p>
                        </div>
                        <div className="cards">
                            <span className="font-souvenir border-[1px] p-3 bg-primary-dark rounded-xl">
                                04
                            </span>
                            <h5>Get Incentivized</h5>
                            <p>Receive financial support.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Define;

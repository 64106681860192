import CanvasBackground from '../../../../../assets/ourProjects/canvas_background.svg';
import './style.css';
function ProjectCanvas({
    title,
    paragraph1,
    paragraph2,
    paragraph3,
    paragraph4,
    paragraph5,
    id,
    projectType,
    region,
    methodology,
    year = false,
    yearNumber,
    methodologyTitle = 'Verra Methodology',
    showYear = true,
}) {
    return (
        <section
            className="flex flex-col bg-primary  relative px-[1.8rem] py-16 md:px-[6rem] md:pt-[11rem] md:pb-[7.3rem] lg:px-[12rem] lg:pt-[13.25rem] lg:pb-[9.3rem]"
            style={{
                backgroundImage: `url(${CanvasBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '36%',
                backgroundPosition: 'bottom right',
            }}
        >
            <h1 className="h2 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                Project Description
            </h1>
            <div className="flex flex-col justify-between lg:flex-row gap-12 2xl:gap-0">
                <div className="flex flex-col gap-6 basis-[68%] 2xl:basis-[60%]">
                    <h2 className="h4 text-yellow font-semibold">{title}</h2>
                    <p className=" text-white">{paragraph1}</p>
                    <p className=" text-white">{paragraph2}</p>
                    <p className=" text-white">{paragraph3}</p>
                    {paragraph4 && <p className=" text-white">{paragraph4}</p>}
                    {paragraph5 && <p className=" text-white">{paragraph5}</p>}
                </div>

                <div className="flex flex-wrap gap-4 justify-between lg:justify-around lg:flex-col">
                    {showYear && (
                        <div>
                            <h3 className="h4 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                                {year ? 'Starting Year' : 'Project ID'}
                            </h3>
                            <p className=" text-white">
                                {year ? yearNumber : id}
                            </p>
                        </div>
                    )}
                    <div className="w-[50%] sm:w-[25%] lg:w-full">
                        <h3 className="h4 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                            Project Type
                        </h3>
                        <p className=" text-white">{projectType}</p>
                    </div>
                    <div className="w-[30%] sm:w-[25%] lg:w-full">
                        <h3 className="h4 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                            Region
                        </h3>
                        <p className=" text-white">{region}</p>
                    </div>
                    <div className="w-[50%] sm:w-[30%] lg:w-full">
                        <h3 className="h4 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                            {methodologyTitle}
                        </h3>
                        <p className=" text-white">{methodology}</p>
                    </div>
                </div>
            </div>
            {/* <img
                className="the-grid w-[40%] opacity-20 absolute bottom-[-22%] right-[-10%]"
                src={CanvasBackground}
                alt="grid"
            /> */}
        </section>
    );
}

export default ProjectCanvas;

import { useEffect } from 'react';
import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    ProjectImpact,
    GeographicalFocus,
    ERWProcess,
} from '../reusables';
import water from '@ourProjectAssets/stats/water.svg';
import fertilizer1 from '@ourProjectAssets/stats/fertilizer.svg';
import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import chart1 from '@ourProjectAssets/stats/chart1.svg';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function ERW() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        GreenRock Initiative Promoting Enhanced <br />
                        Rock Weathering in Madhya Pradesh
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative top-1"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:bottom-1">
                            India |  1 State |  600 Acres
                        </h2>
                    </div>
                }
                // videoLink={CDN_URL + '/ourprojects.mp4'}
                pb="20"
                reverseHeading={true}
                thumbnail={CDN_URL + '/ourProjects/project_07/banner.jpg'}
                mediaType="image"
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'In Madhya Pradesh, India, we are implementing a nature-based carbon sequestration project utilising Enhanced Rock Weathering (ERW) in collaboration with smallholder farmers. ERW involves the application of finely crushed basalt to agricultural land. As rainwater interacts with the rock, it undergoes a natural chemical reaction, capturing atmospheric CO2 and converting it into stable soil carbonates – a permanent form of carbon storage.'
                }
                paragraph2={
                    'ERW improves soil structure and nutrient availability, leading to better water retention and potentially increased crop yields.Through the conversion of CO2 into soil carbonates, ERW contributes to climate change mitigation efforts.'
                }
                paragraph3={
                    'Farmers participating in the project can earn carbon credits, generating additional income while adopting a sustainable land management practice.'
                }
                showYear={false}
                year={true}
                projectType={
                    <>
                        ERW
                        <br /> (Enhanced Rock Weathering)
                    </>
                }
                region={'Madhya Pradesh, India'}
                methodology={'Puro Standard'}
                methodologyTitle="Methodology"
            />
            {/* erw process component */}
            <ERWProcess
                title={'ERW Process'}
                rowOneImages={[
                    { image: '01.jpg', title: 'Byproduct During Mining' },
                    { image: '02.jpg', title: 'Processing of Rock' },
                    { image: '03.jpg', title: 'Loading of Material' },
                ]}
                rowTwoImages={[
                    { image: '04.jpg', title: 'Transportation to Site' },
                    { image: '05.jpg', title: 'Application on Site' },
                    { image: '006.jpg', title: 'Carbon Fate in Environment' },
                ]}
                text={
                    <h3 className="h4 text-yellow">
                        Weathering Phase and Carbon <br />
                        Fate in Environment
                    </h3>
                }
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/india_erw.png',
                    altText: 'india_erw_map',
                }}
                region={'Madhya Pradesh'}
                imageClassName="w-[50%] h-5/6 lg:ml-40"
            />

            <VarahaImpact
                carousal={false}
                sdgs={[
                    '/no_poverty.png',
                    '/hunger.png',
                    '/economy.png',
                    '/inequalities.png',
                    '/climate.png',
                ].map((e) => CDN_URL + '/ourProjects' + '/sdg' + e)}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 5 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            {/* <VisualDiary
                images={['002', '003', '004'].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_07/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + '.jpg',
                }))}
            /> */}
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: water,
                        altText: 'community.svg',
                        title: 'Improved Water Retention',
                    },
                    {
                        imageUrl: chart1,
                        altText: 'community.svg',
                        title: 'Improvement in Soil Nutrients',
                    },
                    {
                        imageUrl: fertilizer1,
                        altText: 'sustainablePractice.svg',
                        title: 'Reduction in Fertilisers Usage',
                    },
                ]}
            />
        </div>
    );
}

export default ERW;

import './style.css';
function SdgCard({ backgroundImage, sdg, title, subtitle }) {
    return (
        <article
            className="rounded-3xl  w-[24rem]  h-[24rem] flex flex-col justify-end bg-[length:100%_100%]  relative bg-primary"
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: 'cover',
            }}
        >
            <img
                src={sdg}
                alt={'sdg-image'}
                className="absolute z-20  top-0 left-0 w-[6.75rem] h-[6.75rem] rounded-tl-3xl rounded-br-3xl"
            />
        </article>
    );
}

export default SdgCard;

import React from 'react';
import members from '@appData/founders';
export default function Sidebar({ idx, close }) {
    const data = members[idx] || { title: 'Not Availble' };
    return (
        <div
            className={`fixed top-0 left-0 right-0 bottom-0 bg-opacity-40  bg-black overflow-y-auto overflow-x-hidden duration-200 transition-all  ${
                idx === -1 ? '-z-10 opacity-0' : 'z-[9999]  opacity-100'
            }`}
        >
            <div
                className={`absolute left-auto right-0  max-w-xl 2xl:max-w-3xl w-full top-0 min-h-screen  bg-primarydark p-3 sm:px-6 xl:px-8 2xl:px-10 sm:py-10 xl:py-14 2xl:py-16 text-white flex flex-nowrap flex-col duration-300  transition-all  delay-200 translate-x-0 first-letter:${
                    idx === -1
                        ? 'translate-x-full -right-full'
                        : 'translate-x-0'
                }`}
            >
                <div className="grid place-content-end md:pr-5 pr-2 pb-8 md:pb-8 lg:pb-12">
                    <button
                        onClick={close}
                        className="stroke-white hover:stroke-yellow p-1 rounded-full border-2 border-white hover:border-yellow"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="inherit"
                            className="w-7 h-7"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div className="flex gap-x-6 gap-y-3 sm:flex-row flex-col">
                    <div className="flex-1  grow-[4] max-h-72 overflow-hidden">
                        <img
                            src={data['displayPic']}
                            alt="team"
                            className="w-full  h-full object-cover object-center"
                        />
                    </div>
                    <div className="flex flex-col justify-end gap-1 flex-1 grow-[3] mt-2">
                        <h4 className="h4 fw-700 text-yellow">
                            {data['name'] || 'Not Available'}
                        </h4>
                        <span className="text-base p">
                            {data['designation'] || ''}{' '}
                        </span>
                        <a
                            href={data['linkedinProfile'] || '#'}
                            className="fill-white hover:fill-gray mr-auto mt-3 md:mt-4 xl:mt-5"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                className="h-8 w-8 fill-inherit"
                            >
                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="grow basis-72 pb-10 shrink pr-3 pt-6 md:pt-8 lg:pt-10">
                    <h6 className="h6 leading-relaxed">{data['desc'] || ''}</h6>
                </div>

                {/*  buttons for moving prev or next :  to be included in later versions. */}
                {/* <div className="flex items-center justify-end gap-10 md:pr-5 pr-2 pb-5  md:pb-1">
          <button className="stroke-white hover:stroke-yellow">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.4083 41.006C32.4551 41.006 41.4103 32.0508 41.4103 21.004C41.4103 9.95717 32.4551 1.00195 21.4083 1.00195C10.3615 1.00195 1.40625 9.95717 1.40625 21.004C1.40625 32.0508 10.3615 41.006 21.4083 41.006Z"
                stroke="inherit"
                strokeWidth="1.17713"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className="stroke-white hover:stroke-yellow">
            <svg
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
              className="stroke-inherit"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.4239 41.006C32.4707 41.006 41.426 32.0508 41.426 21.004C41.426 9.95717 32.4707 1.00195 21.4239 1.00195C10.3771 1.00195 1.42188 9.95717 1.42188 21.004C1.42188 32.0508 10.3771 41.006 21.4239 41.006Z"
                stroke="inherit"
                strokeWidth="1.17713"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
       */}
            </div>
        </div>
    );
}

import React from 'react';
import './creditQuality.css';

import MobileView from './mobileView';

import { creditQuality } from '@appData/tabPanel';

export default function CreditQuality({ defaultTab = 0 }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    return (
        <section className="oursuite-wrapper blade-padding-lg sm:pb-20 pb-10">
            <div className="container">
                <h2
                    className="h1 text-gradient text-center font-medium max-w-screen-md mx-auto leading-tight"
                    data-aos="fade-up"
                >
                    What makes our carbon credits of such high quality?
                </h2>

                <div
                    className="tabbed-container blade-margin-xs sm:flex flex-col hidden"
                    role="tablist"
                    aria-label="Top Footballers"
                    data-aos="fade-up"
                >
                    <div className="tabs-wrapper">
                        {creditQuality.map((tab, index) => {
                            const active = activeTab === index;
                            return (
                                <button
                                    className={`${active ? 'activeTab' : ''
                                        } h5 px-1`}
                                    key={index}
                                    role="tab"
                                    aria-selected={active}
                                    aria-controls={`panel-${index}`}
                                    id={`tab-${index}`}
                                    tabIndex={active ? 0 : -1}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.title}
                                </button>
                            );
                        })}
                    </div>

                    {creditQuality.map((tab, index) => (
                        <section
                            key={index}
                            id={`panel-${index}`}
                            role="tabpanel"
                            tabIndex="0"
                            aria-labelledby={`tab-${index}`}
                            hidden={activeTab !== index}
                            data-aos="fade-up"
                        >
                            <div className="content-wrapper bg-primary ">
                                <div className="left-wrapper">
                                    <div className="w-[70%]">
                                        <img
                                            className="w-full h-full object-cover"
                                            src={tab.src}
                                            alt=""
                                        />
                                    </div>
                                </div>

                                <div className="txt-wrapper bg-primary pl-16 ">
                                    <h2 className="color-yellow h4 ">
                                        {tab.title}
                                    </h2>

                                    <ul className="pr-16 relative track-oursuite">
                                        <li className="h6 text-white py-3 font-light">
                                            {tab.li1}
                                        </li>
                                        {tab.li2 ? (
                                            <li className="h6 text-white py-3 font-light">
                                                {tab.li2}
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {tab.li3 ? (
                                            <li className="h6 text-white py-3 font-light">
                                                {tab.li3}
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    ))}
                </div>

                <div className="oursuite-mobile-view">
                    <MobileView data={creditQuality} />
                </div>
            </div>
        </section>
    );
}

import React from 'react';
import agri from '@partnersAssets/stage/stage-agriculture.png';
import agribg from '@partnersAssets/stage/stage-agriculture-bg.png';
import agro from '@partnersAssets/stage/stage-agroforestry.png';
import agrobg from '@partnersAssets/stage/stage-agroforestry-bg.png';
import BiocharLeaf from '@partnersAssets/stage/Biocharleaf.png';
import forestrybg from '@partnersAssets/stage/stage-forestry-bg.png';
import './index.css';
export default function Stages() {
    return (
        <section className="bg-primary-light blade-padding-sm ">
            <div className="lg:pb-28 pb-20 container flex flex-col items-center">
                <div className="  flex  flex-row-reverse justify-end items-center">
                    <div className="bg-primary rounded-full md:ml-10 ml-5 event">
                        <h3 className="h3 text-gradient md:px-14 px-8 pb-2 pt-1">
                            Regenerative Agriculture
                        </h3>
                    </div>

                    <div className="md:w-[60%] relative target">
                        <img
                            className="w-full md:block hidden"
                            src={agribg}
                            alt=""
                        />
                        <img
                            className="w-24 transition-all duration-500 md:hidden block"
                            src={agri}
                            alt=""
                        />
                        <img
                            className="absolute -bottom-0 md:left-[50%] left-[10%] md:w-[0%] w-20  transition-all duration-500 md:block hidden"
                            src={agri}
                            alt=""
                        />
                    </div>
                </div>

                <div className=" pt-14 flex flex-row justify-start items-center ">
                    <div className="bg-primary rounded-full md:mr-10 mr-2 event">
                        <h3 className="h3 text-gradient md:px-14 px-8 pb-2 pt-1">
                            Afforestation Reforestation and Revegetation
                        </h3>
                    </div>

                    <div className="md:w-[60%] relative target">
                        <img
                            className="w-full md:block hidden"
                            src={agrobg}
                            alt=""
                        />
                        <img
                            className="w-24 transition-all duration-500 md:hidden block"
                            src={agro}
                            alt=""
                        />
                        <img
                            className="absolute -bottom-0 md:left-[50%] left-[0%] md:w-[0%] w-20 transition-all duration-500 md:block hidden"
                            src={agro}
                            alt=""
                        />
                    </div>
                </div>
                <div className=" pt-14 flex  flex-row-reverse justify-end items-center">
                    <div className="bg-primary rounded-full md:ml-10 ml-4 event">
                        <h3 className="h3 text-gradient md:px-14 px-8 pb-2 pt-1">
                            Biochar
                        </h3>
                    </div>

                    <div className="md:w-[60%] relative target ">
                        <img
                            className="w-full md:block hidden"
                            src={forestrybg}
                            alt=""
                        />
                        <img
                            className="w-24  transition-all duration-500 md:hidden block"
                            src={BiocharLeaf}
                            alt=""
                        />
                        <img
                            className="absolute -bottom-8 md:left-[50%] left-[0%] md:w-[0%] w-20  transition-all duration-500 md:block hidden"
                            src={BiocharLeaf}
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

import React from 'react';
import './investors.css';
import imgOne from '@homeAssets/our-suite-end-to-end-project.png';
import imgTwo from '@homeAssets/our-suite-saas-based.png';
import imgThree from '@homeAssets/our-suite-access-hq-carbon-credits.png';

import omnivore from '@homeAssets/institution/omnivore.svg';
import better from '@homeAssets/institution/better.svg';
import rtp from '@homeAssets/institution/rtp.png';
import orios from '@homeAssets/institution/orios.svg';

// import ni from '@homeAssets/partners/ni.png';
import irri from '@homeAssets/partners/irri.png';
import { CDN_URL } from '../../../config';

export default function Investors({ defaultTab = 0 }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);
    const partnersPath = CDN_URL + '/Home/partners/';
    const institutionalInvestPath = CDN_URL + '/Home/InstitutionInvestors/';
    const advisorsPath = CDN_URL + '/Home/advisors/';
    const grantsPath = CDN_URL + '/Home/grants/';
    const investorsPath = CDN_URL + '/Home/investor/';
    const buyersPath = CDN_URL + '/Home/buyers/';

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    const tabs = [
        {
            title: 'Strategic Partners',
            li1: 'Championing partnerships among landowners, investors, NGOs, businesses, and community groups to identify and support carbon removal from nature-based projects.',
            li2: 'Conserving, restoring, and managing natural ecosystems like forests, farmlands, agroforests, mangroves, grasslands, wetlands, etc.',
            li3: 'Ensuring immeasurable benefits like complementary synergies, a global bird’s eye view, and easy access to carbon markets.',
            src: imgOne,
        },
        {
            title: 'Institutional Investors',
            li1: 'An end-to-end climate tech platform equipped with remote sensing, carbon modelling, and in-house proprietary Carbon Quantification Tool (CQT).',
            li2: 'Driving cheaper and more accurate partner enrollment, GHG quantification, measurement, reporting, and verification of projects and carbon credits at scale.',
            li3: 'Ensuring utmost efficiency and transparency at every step of carbon credit creation.',
            src: imgTwo,
        },
        {
            title: 'Angel Investors',
            li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
            li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
            li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
            src: imgThree,
        },
        // {
        //     title: 'Advisors',
        //     li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
        //     li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
        //     li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
        //     src: imgThree,
        // },
        {
            title: 'Buyers',
            li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
            li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
            li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
            src: imgThree,
        },
        {
            title: 'Grants',
            li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
            li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
            li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
            src: imgThree,
        },
    ];

    const institutionLogo = [
        {
            src: omnivore,
        },
        {
            src: rtp,
            width: 'w-[150px]',
        },
        {
            src: institutionalInvestPath + 'norinchukin.png',
            width: 'w-[170px]',
        },
        {
            src: orios,
        },

        {
            src: institutionalInvestPath + 'imc.png',
        },
        {
            src: institutionalInvestPath + 'agfunder.png',
        },
        {
            src: better,
        },
    ];
    const partnersLogo = [
        {
            src: partnersPath + 'the_nature_conservancy.png',
            width: 'w-[260px]',
        },

        {
            src: partnersPath + 'conservation_international_logo.png',
            width: 'w-[260px]',
        },
        {
            src: partnersPath + 'partner4.png',
            width: 'w-[260px]',
        },
        {
            src: partnersPath + 'partner3.png',
            width: 'w-[260px]',
        },
        {
            src: irri,
            width: 'w-[120px]',
        },

        ...[
            'rippleworks.png',
            // 'partner3.png',
            ...[2, 5, 6, 7].map((e) => 'partner' + e + '.png'),
        ].map((e) => ({
            src: partnersPath + e,
            width: 'w-[260px]',
        })),
        ...[
            { img: 'syngenta.png', width: 'w-[260px]' },
            { img: 'muktinath.png', width: 'w-[260px]' },
            { img: 'akrsp.png' },
            { img: 'sabal.png' },
        ].map((e) => ({
            src: CDN_URL + '/ourProjects/partners/' + e.img,
            width: e.width,
        })),
        {
            src: partnersPath + 'partner1.png',
        },

        {
            src: partnersPath + 'IIT.png',
        },
    ];
    const investorsImg = [
        {
            src: investorsPath + 'kunalShah.png',
            alt: 'Kunal Shah',
            name: 'Kunal Shah',
            designation: 'Founder, CRED',
        },
        {
            src: investorsPath + 'sanjaySuri.png',
            alt: 'Sanjay Suri',
            name: 'Sanjay Suri',
            designation: 'CTO & CPO, Nykaa',
        },
        {
            src: investorsPath + 'tom.png',
            alt: 'Sanjay Suri',
            name: "Tom O'Keefe",
            designation: 'Climate-Tech Investor',
        },
    ];
    const advisorImg = [
        {
            src: advisorsPath + 'antonioli.png',
            alt: 'David Antonioli',
            name: 'David Antonioli',
            designation: 'Ex- CEO Verra',
        },
        {
            src: advisorsPath + 'lucas.png',
            alt: 'DR. LUCAS JOPPA',
            name: 'DR. LUCAS JOPPA',
            designation: (
                <>
                    CSO at Haveli and <br />
                    Ex-Chief Environmental <br />
                    Officer, Microsoft
                </>
            ),
        },
    ];
    const grantsImg = ['mulago.png'].map((e) => ({
        src: grantsPath + e,
    }));
    const buyersImg = [
        'callirius.png',
        'goodcarbon.png',
        'klimate.png',
        'carbon_future.png',
    ].map((e) => ({
        src: buyersPath + e,
    }));
    return (
        <section className="envision-wrapper bg-cream blade-padding-lg sm:pb-32 pb-10">
            <div>
                <h2
                    className="h1 text-primary font-medium text-center md:leading-normal leading-tight"
                    data-aos="fade-up"
                >
                    Together envisioning a net zero world
                </h2>

                <div
                    className="tabbed-container blade-margin-xs sm:flex flex-col"
                    role="tablist"
                    aria-label="Top Footballers"
                    data-aos="fade-up"
                >
                    <div className="tabs-wrapper-envision mx-auto  flex-wrap gap-6 justify-center ">
                        {tabs.map((tab, index) => {
                            const active = activeTab === index;
                            let btnClass = `${
                                active ? 'active' : ''
                            } h5 text-gray sm:mx-10 mx-3`;

                            return (
                                <button
                                    className={btnClass}
                                    key={index}
                                    role="tab"
                                    aria-selected={active}
                                    aria-controls={`panel-${index}`}
                                    id={`tab-${index}`}
                                    tabIndex={active ? 0 : -1}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.title}
                                </button>
                            );
                        })}
                    </div>

                    <section
                        key={0}
                        id={`panel-${0}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="0"
                        aria-labelledby={`tab-${0}`}
                        hidden={activeTab !== 0}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center flex-wrap">
                            {partnersLogo.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="sm:px-10 px-5 sm:max-w-none max-w-[100px] my-5"
                                    >
                                        <img
                                            src={item.src}
                                            alt={item.alt}
                                            className={`${
                                                item.width ?? 'w-[150px]'
                                            }`}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                    <section
                        key={1}
                        id={`panel-${1}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="1"
                        aria-labelledby={`tab-${1}`}
                        hidden={activeTab !== 1}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center  flex-wrap">
                            {institutionLogo.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="sm:px-10 px-5 sm:max-w-none max-w-[150px] my-5 "
                                    >
                                        <img
                                            className={
                                                item.width ?? 'w-[200px]'
                                            }
                                            src={item.src}
                                            alt={item.alt}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                    <section
                        key={2}
                        id={`panel-${2}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="2"
                        aria-labelledby={`tab-${2}`}
                        hidden={activeTab !== 2}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center flex-wrap angel-investors">
                            {investorsImg.map((item, index) => {
                                return (
                                    <div
                                        key={item.alt + index}
                                        className="flex flex-col items-center mb-10"
                                    >
                                        <div className="w-64 mx-16">
                                            <img
                                                src={item.src}
                                                alt={item.alt}
                                            />
                                        </div>

                                        <h5 className="h5 font-semibold mt-5 text-primarydark">
                                            {item.name}
                                        </h5>
                                        <p className="p font-normal text-primarydark ">
                                            {item.designation}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </section>

                    {/* <section
                        key={3}
                        id={`panel-${3}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="1"
                        aria-labelledby={`tab-${3}`}
                        hidden={activeTab !== 3}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center  flex-wrap sm:gap-0 gap-8">
                            {advisorImg.map((item, index) => {
                                return (
                                    <div className="flex flex-col items-center justify-center">
                                        <div
                                            key={index}
                                            className="flex flex-col items-center mb-10"
                                        >
                                            <div
                                                className={`w-64 mx-16 relative ${
                                                    index === 0
                                                        ? 'sm:bottom-[1.4rem]'
                                                        : 'bottom-0'
                                                }`}
                                            >
                                                <img
                                                    src={item.src}
                                                    alt={item.alt}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`relative ${
                                                index === 0
                                                    ? 'sm:bottom-[1.4rem]'
                                                    : 'bottom-0'
                                            }`}
                                        >
                                            <h5 className="h5 font-semibold  text-primarydark">
                                                {item.name}
                                            </h5>
                                            <p className="p font-normal text-primarydark text-center">
                                                {item.designation}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section> */}
                    <section
                        key={3}
                        id={`panel-${3}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="1"
                        aria-labelledby={`tab-${3}`}
                        hidden={activeTab !== 3}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center  flex-wrap">
                            {buyersImg.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex flex-col items-center mb-10"
                                    >
                                        <div className="w-64 mx-16">
                                            <img
                                                src={item.src}
                                                alt={item.alt}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                    <section
                        key={4}
                        id={`panel-${4}`}
                        // eslint-disable-next-line
                        role="tabpanel-envision"
                        tabIndex="1"
                        aria-labelledby={`tab-${4}`}
                        hidden={activeTab !== 4}
                        data-aos="fade-up"
                    >
                        <div className="flex justify-center items-center  flex-wrap">
                            {grantsImg.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex flex-col items-center mb-10"
                                    >
                                        <div className="w-64 mx-16">
                                            <img
                                                src={item.src}
                                                alt={item.alt}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </section>
                </div>
            </div>
        </section>
    );
}
// climate tech investor

import { CDN_URL } from '../../config';

const members = [
    {
        name: 'Ikarus Janzen',
        designation: 'Chief Commercial Officer',
        linkedinProfile: 'https://www.linkedin.com/in/ikarus-janzen/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'icarus.png',
        desc: '',
    },
    {
        name: 'Kaushal Bisht',
        designation: 'Lead - Partnerships and Strategic Alliances',
        linkedinProfile: 'https://www.linkedin.com/in/kaushal-bisht-40520116a/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'kaushal.png',
        desc: '',
    },
    {
        name: 'Aditya Kajalia',
        designation: 'Lead - Carbon Supply',
        linkedinProfile: 'https://www.linkedin.com/in/a14adityak/ ',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'aditya.png',
        desc: '',
    },
    {
        name: 'Mitali Chandnani',
        designation: 'Lead - GIS & Remote Sensing',
        linkedinProfile:
            'https://www.linkedin.com/in/dr-mitali-chandnani-126280b8/ ',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'mitali.png',
        desc: '',
    },
    {
        name: 'Gauraang Biyani',
        designation: 'Chief of Staff',
        linkedinProfile: 'https://www.linkedin.com/in/gauraang-b-50881799/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'gaurang.png',
        desc: '',
    },
    {
        name: 'Suman Samanta',
        designation: 'Principal Scientist - Biogeochemical Modeling',
        linkedinProfile: 'https://www.linkedin.com/in/dr-suman-samanta/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'suman.png',
        desc: '',
    },
    {
        name: 'Aravind S',
        designation: 'Principal Engineer - Remote sensing',
        linkedinProfile: 'https://www.linkedin.com/in/aravind-s-39721492/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'aravind.png',
        desc: '',
    },
    {
        name: 'Ranjeet Thapa',
        designation: 'Manager Partnership & Carbon Supply',
        linkedinProfile:
            'https://www.linkedin.com/in/ranjeet-thapa-0511a019a/ ',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'sujeet.png',
        desc: '',
    },
    {
        name: 'Chirag Gupta',
        designation: 'Senior Product Manager',
        linkedinProfile: 'https://www.linkedin.com/in/chirgagupta26/ ',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'chirag.png',
        desc: '',
    },
    {
        name: 'Kevin Lawrence',
        designation: 'Lead, Human Resource',
        linkedinProfile: 'https://www.linkedin.com/in/kevinrlawrence/',
        displayPic: CDN_URL + '/aboutUs' + '/members/' + 'kevin.png',
        desc: '',
    },
];

export default members;

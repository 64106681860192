import React, { useEffect } from 'react';
import Founders from './founders/founders';
import Employee from './Employee/Employee';
import Team from './team/team';
import JoinUs from './joinUs/joinUs';
import ClimateCrisis from './climateCrisis/crisis';
import TeamIntro from './teamIntro/intro';
import Carousel from './carousel/carousel';
import Timeline from './timeline';
import { BannerSecondary } from '../../modules/molecules';
import thumbnail from '@thumbnailsAssets/about.jpg';
import { CDN_URL } from '../../config';
import founders from '@appData/founders';
import members from '@appData/members';
import advisors from '@appData/advisors';
export default function AboutScreen() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BannerSecondary
                heading="Fueling nature-based climate innovations to bend the arc of emissions towards net zero and beyond."
                videoLink={CDN_URL + '/about_us.mp4'}
                pb="20"
                thumbnail={thumbnail}
            />
            <Timeline />
            <ClimateCrisis />
            <TeamIntro />
            <Founders items={founders} title="Founders" sideBar={true} />
            <Founders items={members} title="Team" />
            <Carousel />
            <JoinUs />
        </>
    );
}

function CustomSlider({
    darkArrows,
    activeIndex,
    carousalLength,
    showPageIndex = false,
    buttonSize,
    prevBtnClass,
    nextButtonClass,
    progressBarClass,
    hideProgressBar = false,
}) {
    if (hideProgressBar) {
        return (
            <div className="flex gap-4">
                <button
                    className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 ${prevBtnClass} rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="inherit"
                        className={`${buttonSize} stroke-inherit`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                </button>
                {showPageIndex && (
                    <h4 className="h5 text-white">{`${
                        activeIndex < 9
                            ? '0' + (activeIndex + 1)
                            : activeIndex + 1
                    }`}</h4>
                )}

                {showPageIndex && (
                    <h4 className="h5 text-white">{`${carousalLength}`}</h4>
                )}
                <button
                    className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 ${nextButtonClass} rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="inherit"
                        className={`${buttonSize}stroke-inherit`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                </button>
            </div>
        );
    }
    return (
        <>
            <button
                className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 ${prevBtnClass} rounded-full border-2 flex items-center justify-center transition-all duration-200`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="inherit"
                    className={`${buttonSize} stroke-inherit`}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                </svg>
            </button>
            {showPageIndex && (
                <h4 className="h5 text-white">{`${
                    activeIndex < 9 ? '0' + (activeIndex + 1) : activeIndex + 1
                }`}</h4>
            )}

            <div className={progressBarClass}>
                <span
                    className="bg-yellow rounded-lg transition-all duration-500 overflow-visible h-1 absolute"
                    style={{
                        width: ((activeIndex + 1) / carousalLength) * 100 + '%',
                    }}
                ></span>
            </div>

            {showPageIndex && (
                <h4 className="h5 text-white">{`${carousalLength}`}</h4>
            )}
            <button
                className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 ${nextButtonClass} rounded-full border-2 flex items-center justify-center transition-all duration-200`}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="inherit"
                    className={`${buttonSize}stroke-inherit`}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                </svg>
            </button>
        </>
    );
}

export default CustomSlider;

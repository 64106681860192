import React, { useEffect } from 'react';

export default function ComingSoon() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="h-screen bg-primary flex justify-center items-center">
            <h1 className="h1 text-white "> Coming Soon </h1>
        </div>
    );
}

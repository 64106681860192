const projects = [
    {
        link: 'indo-gangetic',
        text: (
            <>
                Regen Ag Indo-Gangetic <br />
                Plains
            </>
        ),
    },
    {
        link: 'regen-gujarat',
        text: 'Regen Ag Gujarat, India',
    },
    {
        link: 'agro-nepal',
        text: 'ARR Nepal',
    },
    {
        link: 'arr-southindia',
        text: 'ARR South India',
    },
    {
        link: 'agro-bangladesh',
        text: 'ARR Bangladesh',
    },

    {
        link: 'biochar-gujarat',
        text: 'Biochar Artisan India',
    },
    {
        link: 'puro-industrial-glasifier-telangana',
        text: 'Biochar High-Tech India',
    },

    {
        link: 'erw-jarkhand-chattisgarh',
        text: 'ERW India',
    },
];

export default projects;

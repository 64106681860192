import arrowDark from '@iconsAssets/arrow-dark.svg';
import varahaOutline from '@globalAssets/varaha-dashed-logo.png';
import grid from '@homeAssets/grid-view.svg';
import './projectCards.css';
import { NavLink } from 'react-router-dom';

const ProjectCards = ({ thumbnail, heading, para, listData, to }) => {
    return (
        <>
            <div
                data-aos="fade-up"
                className="project-cards max-w-screen-md w-full h-fit overflow-hidden"
            >
                <div className="bg-primary h-[140px]">
                    <img
                        className="the-grid w-[60%] opacity-30 absolute top-0 left-0"
                        src={grid}
                        alt="grid"
                    />
                </div>

                <div
                    className="thumbnail z-20"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8)), url(${thumbnail})`,
                    }}
                >
                    <h2 className="sm:text-3xl text-2xl leading-normal absolute sm:left-10 left-5 bottom-10 z-50 text-white xl:max-w-xl max-w-xs pr-5">
                        {heading}
                    </h2>
                </div>

                <div className="hovered-content px-6 py-3 2xl:p-6 ">
                    <h4 className="h4 font-bold text-gradient">{heading}</h4>
                    <h6 className="p font-light mt-3 text-white xxl:pr-10 pr-0 leading-relaxed">
                        {para}
                    </h6>

                    <div className="flex flex-nowrap z-40">
                        <ul className="mt-5">
                            {listData.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <h5 className="p xl:pb-2 pb-1  text-white font-light xl:min-w-[132px]">
                                            {item.lh}
                                        </h5>
                                    </li>
                                );
                            })}
                        </ul>

                        <ul className="mt-5 ">
                            {listData.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <p className="p font-semibold xl:pb-2 pb-1 dashed-lis text-gradient">
                                            {item.li}
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>

                <div className="right-boxes absolute right-0 top-0 z-10 h-full md:block hidden">
                    <NavLink to={to}>
                        <div className="arrow">
                            <img className="w-16" src={arrowDark} alt="" />
                        </div>
                    </NavLink>

                    <div className="varaha-outline  absolute bottom-0 right-0 pl-5">
                        <div className="absolute bottom-0 right-0">
                            <img
                                className="w-[100%]"
                                src={varahaOutline}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectCards;

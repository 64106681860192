import ankita from '@aboutAssets/founders/ankita-garg.png';
import madhur from '@aboutAssets/founders/madhur-jain.png';
import vishal from '@aboutAssets/founders/vishal.png';

const members = [
    {
        name: 'Madhur Jain',
        designation: 'Co-founder and CEO',
        linkedinProfile:
            'https://www.linkedin.com/in/madhur-jain-b3a01641/?originalSubdomain=in',
        displayPic: madhur,
        desc: 'In his last assignment, Madhur served as the Digital business lead for the South Asia arm of the world’s most valued Ag-Tech startup – Indigo Ag and built three distinct business verticals and teams from scratch. He also served as the first ‘India Country Director’ for a global social enterprise - PxD, which was co-founded by Economics Nobel prize laureate (2019) Michael Kremer. During his time, Madhur grew the smallholder farmer user base from 5,000 to over 615,000. Madhur has a background in Engineering and an MBA from Indian Institute of Management – Ahmedabad.',
    },
    {
        name: 'Ankita Garg',
        designation: 'Co-founder and COO',
        linkedinProfile:
            'https://www.linkedin.com/in/ankitagarg1/?originalSubdomain=in',
        displayPic: ankita,
        desc: 'Ankita has worked for 10+ years in global companies including Bayer, Monsanto, Covance, and Genentech across the USA, Germany, and India, and has experience of leading business portfolios above >$100 million. Ankita, previously also built and led another startup, which involved the design and development of IoT sensors to provide farm-level customized advisory to horticultural farmers. She holds an MS in Biotechnology from Pennsylvania State University, USA, and an MBA from IIM – Ahmedabad.',
    },
    {
        name: 'Vishal Kuchanur',
        designation: 'Co-founder and CTO',
        linkedinProfile:
            'https://www.linkedin.com/in/vishalkuchanur/?originalSubdomain=in',
        displayPic: vishal,
        desc: 'Vishal has a total experience of 13+ years and has served as an Engineering Manager at category leading businesses from India, such as Flipkart, Big Basket and CropIn. He also had a stint at Goldman Sachs as a software developer. He holds a B.Tech in Electronics and communication engineering',
    },
];
export default members;

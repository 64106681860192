import { useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import {
    projectList,
    mobileProjectList,
    totalProjects,
} from '../config/config';
import { GenericProjectCards } from '../../../modules/atoms';
import { NavButton } from '../../../modules/atoms';
import 'swiper/css';

function ProjectList({ darkArrows = false }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const sectionRef = useRef();
    const textContentRef = useRef();
    const projectHeadingRef = useRef();
    const numberRef = useRef();
    const calcNavStr = (index) => {
        switch (index) {
            case 0:
                return 'indo-gangetic';
            case 1:
                return 'agro-nepal';
            case 2:
                return 'agro-bangladesh';
            case 3:
                return 'biochar-gujarat';
            case 4:
                return 'regen-gujarat';
            case 5:
                return 'arr-southindia';
            default:
                return 'erw-madhya-pardesh';
        }
    };
    const calcDenominatorFactor = () => {
        if (window.screen.width > 1450 && window.screen.width <= 1520) {
            return 14;
        } else if (window.screen.width > 1400 && window.screen.width <= 1450) {
            return 17;
        } else if (window.screen.width > 1300 && window.screen.width <= 1400) {
            return 20;
        } else if (window.screen.width > 1280 && window.screen.width <= 1300) {
            return 24;
        } else {
            return 8;
        }
    };

    return (
        <>
            <section
                className={
                    'hidden sm:flex sm:pt-14 sm:pl-[3.98rem] md:pt-40 md:pl-40 sm:justify-start  sm:pb-20'
                }
                style={{
                    backgroundImage: `var(--bg-image)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    transition: 'all 0.3s ease',
                }}
                id="carousal-id"
                ref={sectionRef}
            >
                <div className="flex flex-col">
                    <div className="flex flex-col gap-[0.69rem] ">
                        <h1
                            className="text-white h1 font-semibold"
                            ref={numberRef}
                        >
                            {projectList[activeIndex].number}
                            <span className="h4 opacity-80 text-white">
                                /07
                            </span>
                        </h1>

                        <div className="flex flex-col xl:flex-row">
                            <div className="flex flex-col justify-between w-auto sm:w-[43rem] 2xl:w-[46rem] gap-4 xl:gap-0">
                                <div className="flex flex-col gap-[1.3rem]">
                                    <div ref={projectHeadingRef}>
                                        {
                                            projectList[activeIndex]
                                                .titleComponent
                                        }
                                    </div>

                                    <div className="w-[90%]">
                                        <h2
                                            className="text-white h5 text-[1.4rem] hidden sm:inline"
                                            ref={textContentRef}
                                        >
                                            {projectList[activeIndex].subTitle}
                                        </h2>
                                    </div>
                                </div>
                                <NavButton
                                    title={'Learn more'}
                                    reverse={false}
                                    className="mb-6"
                                    link={calcNavStr(activeIndex)}
                                    svgFill="white"
                                    hoverFillColor={'#004345'}
                                />
                            </div>
                            {/* w-full md:w-[40rem] */}
                            <div className="overflow-hidden w-[99vw]  lg:w-[69rem]">
                                <div>
                                    <Swiper
                                        modules={[
                                            Navigation,
                                            Pagination,
                                            Autoplay,
                                        ]}
                                        autoHeight={true}
                                        speed={500}
                                        spaceBetween={10}
                                        loop={true}
                                        slidesPerView="auto"
                                        keyboard={true}
                                        initialSlide={0}
                                        autoplay={{ delay: 5000 }}
                                        onSlideChange={(swiper) => {
                                            setActiveIndex(
                                                swiper.activeIndex %
                                                    totalProjects
                                            );
                                            document.documentElement.style.setProperty(
                                                '--bg-image',
                                                `url(${
                                                    projectList[
                                                        swiper.activeIndex %
                                                            totalProjects
                                                    ].imageUrl
                                                })`
                                            );
                                        }}
                                        navigation={{
                                            nextEl: '.next-btn',
                                            prevEl: '.prev-btn',
                                        }}
                                        pagination={{
                                            bulletClass: 'p-2 bg-red',
                                        }}
                                        className="mySwiper"
                                    >
                                        {projectList.map((el) => (
                                            <SwiperSlide
                                                style={{
                                                    display: 'flex',
                                                    flexBasis: '23rem',
                                                }}
                                                key={el.id}
                                            >
                                                <GenericProjectCards
                                                    justifyDiscription="justify-end"
                                                    cardContainer="flex-col"
                                                    projectImage={
                                                        el.secImageUrl
                                                    }
                                                    subTitle={el.title}
                                                    key={el.id}
                                                    projectNumber={el.number}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center xl:justify-end">
                        <div className="flex items-center  w-[62%] gap-4 justify-center ">
                            <section className="flex gap-2">
                                <button
                                    className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 prev-btn rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="inherit"
                                        className="w-6 h-6 stroke-inherit"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 19.5L8.25 12l7.5-7.5"
                                        />
                                    </svg>
                                </button>
                                <button
                                    className={`opacity-80
                            ${
                                darkArrows
                                    ? 'hover:stroke-primary stroke-primarydark border-primarydark hover:border-primary'
                                    : 'hover:stroke-yellow stroke-white border-white hover:border-yellow'
                            }
                               xl:p-4 lg:p-3 p-2 next-btn rounded-full border-2 flex items-center justify-center transition-all duration-200`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="inherit"
                                        className="w-6 h-6 stroke-inherit"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        />
                                    </svg>
                                </button>
                            </section>

                            <div
                                className={` bg-primarylight h-1 overflow-visible w-full rounded-lg relative 2xl:w-[88%]`}
                            >
                                <span
                                    className="bg-yellow rounded-lg transition-all duration-500 overflow-visible h-1 absolute"
                                    style={{
                                        width:
                                            activeIndex === 6
                                                ? '100%'
                                                : (activeIndex /
                                                      calcDenominatorFactor()) *
                                                      100 +
                                                  '%',
                                    }}
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="flex flex-col gap-4   bg-primarydark p-4 sm:hidden"
                // style={{ visibility: 'hidden' }}
            >
                {mobileProjectList.map((el, index) => (
                    <div
                        className="flex flex-col rounded-lg pt-14 pb-14 px-4 gap-8 projectcard justify-between"
                        style={{
                            backgroundImage: `url(${el.imageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                        key={`${index}-projectlist`}
                    >
                        <div className="flex flex-col gap-[0.2rem]">
                            <h1 className="text-white h1">
                                {el.number}
                                <span className="p opacity-80 text-white">
                                    /07
                                </span>
                            </h1>
                            {el.titleComponent}
                        </div>
                        <NavButton
                            title={'Learn more'}
                            reverse={false}
                            className="mb-6 self-end"
                            link={calcNavStr(index)}
                            type={'smallButton'}
                            svgFill="white"
                            hoverFillColor={'#004345'}
                            gradientId={'nav-button-mobile' + index}
                        />
                    </div>
                ))}
            </section>
        </>
    );
}

export default ProjectList;

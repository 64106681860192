import React, { useEffect, useState } from 'react';
import './guidelines.css';
import { ImCross } from 'react-icons/im';

export default function Guidelines() {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => { }, []);

    return (
        <section className="guidelines-wrapper blade-padding-lg bg-primary pb-20">
            <div className="container">
                <div className="xl:max-w-screen-md lg:max-w-screen-sm">
                    <h2
                        className="h3 text-gradient font-medium"
                        data-aos="fade-up"
                    >
                        Reward system enabling partnerships across the portfolio
                    </h2>

                    <div className="py-4">
                        <ul className="guidelines-paragraph track-decorbonize z-0 md:mr-10 mr-4">
                            <li
                                className="h5 text-white leading-normal font-extralight my-5"
                                data-aos="fade-up"
                            >
                                Majority of the compensation from the sale of
                                carbon credits is returned back to the
                                smallholder communities.
                            </li>

                            <li
                                className="h5 text-white leading-normal font-extralight my-5 relative"
                                data-aos="fade-up"
                            >
                                Varaha ensures the inclusion of farmers, FPOs,
                                NGOs, agroforestry, & forestry practitioners to
                                carbon markets who cannot contribute due to
                                smaller volumes of produce.
                            </li>
                        </ul>
                    </div>

                    <div
                        className={` ${isShow
                                ? 'opacity-100 z-1 block'
                                : 'opacity-0 hidden -z-[999]'
                            } popup-wrapper h-screen transition-all duration-500 fixed top-0 right-0`}
                    >
                        <div
                            className={`${isShow ? 'translate-x-0' : 'translate-x-full'
                                }
                popup-card bg-primary shadow-xl relative max-w-xl p-8 rounded-xl md:mx-20 mx-auto transition-all duration-500 pt-40`}
                        >
                            <div
                                onClick={() => {
                                    setIsShow(false);
                                }}
                                className="absolute top-8   cursor-pointer right-8 w-12 h-12 rounded-full border-2 border-white place-content-center grid lg:hidden "
                            >
                                <ImCross className=" text-white text-lg" />
                            </div>

                            <h2 className="h2 text-white font-souvenir leading-snug">
                                Carbon <br /> Credits
                            </h2>

                            <p className="h5 text-white sm:mt-5">
                                Carbon Credits represent quantities of
                                greenhouse gases that have been sequestered or
                                kept out of the atmosphere as a result of
                                regenerative agricultural/forestry practices.
                                Purchasing them is a way for a company to
                                address emissions it cannot eliminate.
                                Numerically speaking, 1 tonne of CO
                                <sub>2</sub> reduction or removal equals 1
                                carbon credit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

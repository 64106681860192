import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { CustomSlider } from "../../../../../modules/atoms";
import { useState } from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "../../../style.css";
function VisualDiary({ images }) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="hidden md:block relative bg-primarydark">
        <Swiper
          autoHeight={true}
          speed={300}
          navigation={{
            nextEl: ".diary-next-btn",
            prevEl: ".diary-prev-btn"
            // disabledClass: 'swiper-button-disabled',
          }}
          onSlideChange={(swiper) => {
            // console.log(swiper.realIndex);
            // debugger;

            setActiveIndex(swiper.realIndex);
          }}
          modules={[EffectFade, Navigation, Pagination, Autoplay]}
          spaceBetween={0}
          effect={"fade"}
          loop={true}
          slidesPerView={"1"}
          className="mySwiper"
          autoplay={true}
        >
          {images.map((e, index) => (
            <SwiperSlide
              key={`0${index + 1}_diary`}
              // style={{
              //     minHeight: '100vh',
              // }}
              // className="w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] lg:w-[25rem] lg:h-[25rem] xl:w-[50rem] xl:h-[50rem]"
            >
              <div
                style={{
                  width: "100%",
                  height: "100%"
                  // position: 'relative',
                  // overflow: 'hidden',
                }}
              >
                <img
                  src={e.imageUrl}
                  key={e.id}
                  alt={e.altText}
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="flex flex-col items-center justify-between h-[100%] absolute  z-10 top-0 w-full py-12">
          <div className="flex justify-center">
            <h1 className="h2 text-white">Visual Diary</h1>
          </div>
          <div className="flex justify-center">
            <div className="flex gap-2 items-center w-full justify-center sm:w-[40rem] overflow-hidden">
              <CustomSlider
                darkArrows={false}
                showPageIndex={false}
                buttonSize={"w-6 h-6"}
                activeIndex={activeIndex}
                carousalLength={images.length}
                prevBtnClass={"diary-prev-btn"}
                nextButtonClass={"diary-next-btn"}
                progressBarClass={
                  "bg-primarylight h-1 overflow-visible w-full rounded-lg relative hide-progressbar"
                }
              />
            </div>
          </div>
        </div>
        {/* top-6 sm:top-16 */}
        {/* bottom-6 sm:bottom-12 z-10 */}
      </section>
      <section className="flex flex-col gap-8 md:hidden bg-primarydark  p-4">
        <h1 className="h2  text-red-50 text-center">Visual Diary</h1>
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <Swiper
              autoHeight={true}
              speed={1000}
              navigation={{
                nextEl: ".diary-next-btn-mobile",
                prevEl: ".diary-prev-btn-mobile"
              }}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.realIndex);
              }}
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={20}
              effect={"creative"}
              loop={true}
              slidesPerView={"3"}
              className="mySwiper"
              autoplay={true}
            >
              {images.map((e, index) => (
                <SwiperSlide
                  key={`0${index + 1}_diary`}
                  style={{
                    minWidth: "18rem"
                  }}
                  // className="w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] lg:w-[25rem] lg:h-[25rem] xl:w-[50rem] xl:h-[50rem]"
                >
                  <img
                    src={e.imageUrl}
                    key={e.id}
                    alt={e.altText}
                    className="object-contain object-top w-full h-full"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="flex gap-2 items-center w-full justify-center ">
            <CustomSlider
              darkArrows={false}
              showPageIndex={false}
              buttonSize={"w-6 h-6"}
              activeIndex={activeIndex}
              carousalLength={images.length}
              prevBtnClass={"diary-prev-btn-mobile"}
              nextButtonClass={"diary-next-btn-mobile"}
              progressBarClass={
                "bg-primarylight h-1 overflow-visible w-full rounded-lg relative hide-progressbar"
              }
              hideProgressBar={true}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default VisualDiary;

import React, { useEffect } from 'react';


const heading = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'];
const spacing = [1, 2, 3, 4, 5, 6, 10, 12, 14, 16, 20, 24, 32, 64]

const Type = () => {
    return (
        <>
            <div className='bg-primary-dark text-white p-32'>
                <h1 className='h1'>Typography</h1>
            </div>
            <div className='bg-light-green'>
                <ul className='p-32 flex gap-8 flex-col'>
                    {heading.map((item, key) => (
                        <li key={key} className={item + ' fw-400'}>
                            {item} / 400 : Reversing climate change through conscious nature-based interventions
                        </li>
                    ))}
                </ul>
            </div>
            <hr />
            <div className='bg-light-green'>
                <ul className='p-32 flex gap-8 flex-col'>
                    {heading.map((item, key) => (
                        <li key={key} className={item + ' fw-500'}>
                            {item} / 500 : Reversing climate change through conscious nature-based interventions
                        </li>
                    ))}
                </ul>
            </div>
            <hr />
            <div className='bg-light-green'>
                <ul className='p-32 flex gap-8 flex-col'>
                    {heading.map((item, key) => (
                        <li key={key} className={item + ' fw-600'}>
                            {item} / 600 :  Reversing climate change through conscious nature-based interventions
                        </li>
                    ))}
                </ul>
            </div>
            <hr />
            <div className='bg-light-green'>
                <ul className='p-32 flex gap-8 flex-col'>
                    {heading.map((item, key) => (
                        <li key={key} className={item + ' fw-700'}>
                            {item} / 700 :  Reversing climate change through conscious nature-based interventions
                        </li>
                    ))}
                </ul>
            </div>

            <div className='bg-primary-dark text-white p-32'>
                <h1 className='h1'>Spacing</h1>
            </div>
            {spacing.map((item, key) => (
                <>
                    <div className={'pt-' + item}>{item}</div>
                    <hr />
                </>
            ))}
        </>
    );
};
export default Type;

import React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import './faqs.css';

export default function Faqs() {
    const Dropdown = ({
        question,
        answers,
        answers_1,
        idx,
        li_1,
        li_2,
        li_3,
        li_4,
        li_5,
        li_6,
    }) => {
        return (
            <div className="flex rounded-xl mt-5 bg-primary-light">
                <label className="data-card cursor-pointer w-full">
                    <input
                        type="radio"
                        defaultChecked={idx === 0}
                        autoFocus={idx === 0}
                        name="practices-option"
                    />
                    <div className="wrapper py-6 px-10">
                        <div className="flex justify-between  pt-1 ">
                            <h5 className="h5 text-primary font-medium pr-3">
                                {question}
                            </h5>
                            <div className="bg-primary rounded-md h-fit">
                                <MdKeyboardArrowDown className="arrow h3 text-white" />
                            </div>
                        </div>
                        <div className="answer max-w-3xl">
                            <h6 className="h6 text-black font-normal pt-5 leading-relaxed">
                                {answers}
                            </h6>

                            {li_1 ? (
                                <li className="h6 text-black font-normal pt-2 pl-3 leading-relaxed">
                                    {li_1}
                                </li>
                            ) : (
                                ''
                            )}
                            {li_2 ? (
                                <li className="h6 text-black font-normal pt-2 pl-3 leading-relaxed">
                                    {li_2}
                                </li>
                            ) : (
                                ''
                            )}
                            {li_3 ? (
                                <li className="h6 text-black font-normal pt-2 pl-3 leading-relaxed">
                                    {li_3}
                                </li>
                            ) : (
                                ''
                            )}
                            {li_4 ? (
                                <li className="h6 text-black font-normal pt-2 pl-3 leading-relaxed">
                                    {li_4}
                                </li>
                            ) : (
                                ''
                            )}
                            {li_5 ? (
                                <li className="h6 text-black font-normal pt-2 pl-3 leading-relaxed">
                                    {li_5}
                                </li>
                            ) : (
                                ''
                            )}

                            <h6 className="h6 text-black font-normal pt-5 leading-relaxed">
                                {answers_1}
                            </h6>
                        </div>
                    </div>
                </label>
            </div>
        );
    };
    const agriData = [
        {
            question: 'What are carbon credits?',
            answers:
                'Carbon Credits represent quantities of greenhouse gases that have been sequestered or kept out of the atmosphere as a result of regenerative agricultural/forestry practices. Purchasing them is a way for a company to address emissions it cannot eliminate. Numerically speaking, 1 tonne of CO₂ reduction or removal equals 1 carbon credit',
        },
        {
            question: 'Zero Till Farming',
            answers:
                'We work with all types of Farmers, Farmer Producer Organizations, and NGOs promoting sustainable farming practices such as',
            li_1: 'No-till Farming',
            li_2: 'Residue Management',
            li_3: 'Direct Seeding Rice',
            li_4: 'Less usage of Synthetic Fertilizers & Water',
            li_5: 'Optimized use of Electricity & Fuel',
            li_6: 'Effective Livestock Manure Management.',
            answers_1: `These practices improve productivity 
      by increasing soil organic content and reducing the release of harmful gasses into the environment.`,
        },
        {
            question: 'What are the eligibility criteria?',
            li_1: `Should follow certain regenerative practices not limited to Direct Seeding Rice, Farm Yard Manure,
       Composting, No Residue Burning, Crop Rotation, Crop Cover, etc.`,
            li_2: 'Should share authentic data with us.',
        },
        {
            question: ' What data & documents do I need to share?',
            answers: `Land boundary, geolocation, number and type of crops, tillage, water management practices, nutrient management practices, laser leveler, residue management 
        techniques used, personal ID card, bank details, and land ownership records`,
        },
        {
            question: 'When & how will the landowner be incentivized?',
            answers:
                "Right after carbon credit generation, the majority of the income will be transferred to the landowner which usually takes 6-8 months once the data is gathered from the land owner. This income will be transferred to the landowner's bank account.",
        },
        {
            question:
                'What will be the audit process? When will the auditor visit my land?',
            answers: `Auditors will visit a few farmers which may or may not be right after we collect and quantify the data from our land.
       Landowners will be informed earlier in case an auditor’s visit is planned.`,
        },
    ];
    return (
        <div className="faqs-blade-wrapper bg-cream md:pb-40 pb-20">
            <div className=" blade-padding-lg">
                <div className="container">
                    <h3 className="text-center h2 font-medium text-primary">
                        Diving deep into some FAQs
                    </h3>
                    <div className="flex flex-col mt-10">
                        {agriData.map((item, index) => {
                            return (
                                <Dropdown
                                    key={index}
                                    idx={index}
                                    question={item.question}
                                    answers={item.answers}
                                    answers_1={item.answers_1}
                                    li_1={item.li_1}
                                    li_2={item.li_2}
                                    li_3={item.li_3}
                                    li_4={item.li_4}
                                    li_5={item.li_5}
                                    li_6={item.li_6}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import { CardFlex } from '../../../modules/molecules';
import benefitsImg1 from '@businessAssets/benefits/benefitsImg(1).png';
import benefitsImg2 from '@businessAssets/benefits/benefitsImg(2).png';
import benefitsImg3 from '@businessAssets/benefits/benefitsImg(3).png';
import benefitsImg4 from '@businessAssets/benefits/benefitsImg(4).png';
import benefitsImg5 from '@businessAssets/benefits/benefitsImg(5).png';
import benefitsImg6 from '@businessAssets/benefits/benefitsImg(6).png';

export default function Benefits() {
    return (
        <div className="bg-cream blade-padding-sm">
            <div className="container ">
                <h1
                    className="h2 text-center text-primary font-medium"
                    data-aos="fade-up"
                >
                    What are the co-benefits?
                </h1>
                <CardFlex
                    heading="Biodiversity in quantity and variety"
                    subhead_1="Extensive research on reasons, mechanisms, and incentives that can motivate communities to implement climaIncreasing biomass is imperative to continue afforestation and prevent desertification. From friendly pollinators to endangered species, nurturing plantation attracts flora and fauna of all sorts thus making the overall ecosystem thrive through variety in diversity.te-positive practices."
                    image={benefitsImg1}
                />
                <CardFlex
                    heading="Community Income"
                    subhead_1="Most of the farmers in India and other Asian countries are small and marginalized (88% of the farmers possess less than 2 hectares of land). 65%-75% of the income generated from carbon credits will be passed on to farmers which will significantly help in increasing the income of the smallholder communities further adding up to overcoming poverty."
                    image={benefitsImg2}
                    reverse
                />
                <CardFlex
                    heading="Improved soil quality"
                    subhead_1="A profound diversity in flora prevents soil erosion and exhaustion by ensuring optimum nutrient levels in the soil. This further adds up to increased yield and health of the produce."
                    image={benefitsImg3}
                />
                <CardFlex
                    heading="Overcoming Pollution"
                    subhead_1="Eliminating the need to burn crop residue reduces pollution and overcomes various health hazards."
                    image={benefitsImg4}
                    reverse
                />
                <CardFlex
                    heading="Addressing water scarcity"
                    subhead_1="With consistent and robust plant growth over an area, the problem of water scarcity virtually ceases to exist as the roots end up retaining water permeated into the soil."
                    image={benefitsImg5}
                />
                <CardFlex
                    heading="Sustainable Development Goals; no longer a far-fetched dream"
                    subhead_1="Incentivising carbon-neutral nature-based practices and formulating technologies for carbon removal will together help the smallholder-based developing economies achieve their respective Sustainable Development Goals (SDGs) with ease."
                    image={benefitsImg6}
                    reverse
                />
            </div>
        </div>
    );
}

import { useEffect } from 'react';
import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    Practices,
    GeographicalFocus,
} from '../reusables';
import { indoSdgContent, practicesTabs } from '../../config/config';
import community from '@ourProjectAssets/stats/community.svg';
import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import farmer from '@ourProjectAssets/stats/farmer.svg';
import acre from '@ourProjectAssets/stats/acre.svg';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function IndoGangetic() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        Indo-Gangetic Plains-focused Regenerative Agriculture
                        Project
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative sm:left-2"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:relative sm:bottom-1">
                            India  |  7 States  |  2.024 million Hectares
                        </h2>
                    </div>
                }
                thumbnail={CDN_URL + '/ourProjects/project_01/banner.png'}
                mediaType="image"
                pb="20"
                reverseHeading={true}
                // mediaType={'image'}
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'The project is a large-scale grouped project activity that promotes faster implementation of improved agricultural practices by farmers for reducing greenhouse gas emissions, restoring degraded land, and promoting carbon sequestration while enhancing soil health and increasing crop yields. The project employs regenerative agriculture practices such as reduced tillage, Direct Seeding of Rice (DSR), crop residue management, crop rotation, and use of cover crops.'
                }
                paragraph2={
                    'Before implementation of the project activity, farmers in the project followed conventional agricultural practices such as tillage, flooding, and burning of crop residues leading to the degradation of soil and greenhouse gas emissions in the atmosphere. The project reduces these negative externalities while creating a large carbon sink through soil carbon removal.Project activities started in June 2019 by implementation partners focused on the rice-wheat cropping system in the Indian states of Punjab and Haryana.'
                }
                paragraph3={
                    ' The project is being further expanded to maize-wheat, sugarcane, and cotton-wheat/cotton cropping systems. Pulses and mustards are cover crops that are widely interspersed in these systems. As of today, the project had onboarded over 51,000 farmers across 600,000 Acres of farmland across 7 states in India.'
                }
                id={3346}
                projectType={
                    <>
                        ALM
                        <br />
                        (Agriculture Forestry and Other Land Use AFOLU)
                    </>
                }
                region={'Indo-Gangetic Plains, India'}
                methodology={'VM0042'}
            />
            <Practices title={'Adopted Practices'} tabs={practicesTabs} />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/india.png',
                    altText: 'india_map',
                }}
                region={'India'}
                imageClassName="w-[35%] h-5/6 lg:ml-40"
            />
            <ImplementationPartners
                title={'Implementation Partners'}
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/roots.png',
                        altText: 'roots.png',
                        backgroundColor: '#88C53C',
                        title: <>Roots Foundation</>,
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/organic.png',
                        altText: 'organic.png',
                        backgroundColor: '#FBF9C8',
                        title: (
                            <>
                                Agriculture Awareness & <br />
                                Organic Farming <br />
                                Society
                            </>
                        ),
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',
                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/ozone.png',
                        altText: 'ozone.png',
                        backgroundColor: 'white',
                        title: <>Ozone Farmer </>,
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',

                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/bombay.png',
                        altText: 'ozone.png',
                        backgroundColor: 'white',
                        title: <>Bombay Institute </>,
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',

                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/rcico.png',
                        altText: 'ozone.png',
                        backgroundColor: 'white',
                        title: (
                            <>
                                Rcico Farmer Producer <br />
                                Company Limited
                            </>
                        ),
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',

                        padding: '',
                    },
                ]}
            />
            <VarahaImpact
                carousal={true}
                contents={indoSdgContent}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 12 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={[
                    '001',
                    '002',
                    '003',
                    '004',
                    '005',
                    '006',
                    '008',
                    '009',
                    '010',
                    // '011',
                    // '012',
                    '013',
                    '015',
                    // '016',
                    '017',
                    '018',
                    '019',
                    // '020',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_01/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + 'jpg',
                }))}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: farmer,
                        altText: 'water.svg',
                        start: 50000,
                        end: 51000,
                        unit: '',
                        description: 'Onboarded Farmers',
                        title: 'Onboarded Farmers',
                    },
                    {
                        imageUrl: acre,
                        altText: 'community.svg',
                        start: 599000,
                        end: 600000,
                        unit: '',
                        description: 'Acres Onboarded',
                        title: '',
                    },
                    {
                        imageUrl: community,
                        altText: 'sustainablePractice.svg',
                        start: 1210203,
                        end: 1211203,
                        unit: '',
                        description: 'tonnes of carbon sequestered',
                        title: '',
                    },
                ]}
            />
        </div>
    );
}

export default IndoGangetic;
//syngenta: px-[2.12rem] py-[2.31rem] sm:px-[6.12rem] sm:py-[3.31rem]

import React, { useEffect } from 'react';

import { Autoplay, Pagination, Navigation } from 'swiper';

import Funding1 from '../assets/img/funding/slide1.png';
import Funding2 from '../assets/img/funding/slide2.png';
import Funding3 from '../assets/img/funding/slide3.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function Disclaimer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section className="bg-primary-dark md:pt-64 md:pb-32 pt-48 pb-16">
                <div className="container md:grid md:grid-cols-12 gap-16">
                    <div className="md:col-span-7 pb-16 md:pb-0">
                        <span className="text-white">Varaha updates</span>
                        <h1 className="h1 text-white mb-2">
                            $8.7 Million to accelerate Agricultural
                            Decarbonization
                        </h1>
                        <span className="text-white">in Series A Funding</span>
                    </div>
                    <div className="md:col-span-5">
                        <div className="w-full">
                            <Swiper
                                modules={[Autoplay]}
                                slidesPerView={1}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                    <img src={Funding1} alt="Funding" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Funding2} alt="Funding" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={Funding3} alt="Funding" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-cream py-24 text-green ">
                <div className="container">
                    <div className="">
                        <span className="block mb-2">
                            India, New Delhi & Bengaluru, February 22, 2024{' '}
                        </span>
                        <h2 className="h2 mb-4">
                            Varaha Emerges as a Frontrunner in Combating Climate
                            Change in the Developing World through Nature-Based
                            Carbon Credits
                        </h2>

                        <h3 className="h5 mb-8">
                            With this funding, Varaha will increase its
                            geographic presence in Southeast Asia and
                            Sub-Saharan Africa and bolster tech and scientific
                            capabilities
                        </h3>

                        <p className="mb-6">
                            Varaha, a pioneering full-stack climate-tech company
                            that specializes in generating carbon credits from
                            nature,  today announced it has raised US$ 8.7
                            Million. The Series A funding round is led by RTP
                            Global with contributions from Omnivore and Orios –
                            both returning investors – and marks the inaugural
                            investment by Norinchukin Bank, one of Japan's
                            largest institutional investors, in an Indian
                            startup. The round also saw participation from
                            AgFunder and Octave Wellbeing Economy Fund, an
                            investment arm of IMC Pan Asia Alliance Group..
                        </p>

                        <p className="mb-6">
                            Varaha's trailblazing efforts have positioned it as
                            a leading end-to-end project developer for carbon
                            credits, incentivizing thousands of smallholders
                            towards sustainable practices. Varaha’s
                            industry-leading Measurement, Reporting and
                            Verification (MRV) platform uses remote sensing,
                            machine learning and scientific research to quantify
                            the sequestration and reduction of greenhouse gases
                            from regenerative agriculture, afforestation and
                            biochar projects. Their  projects also enhance
                            productivity, boost crop yields, save water,
                            increase biodiversity and raise climate adaptation.
                            Varaha serves as a one-stop destination for leading
                            global companies striving to achieve net-zero
                            through its diversified portfolio offerings of
                            high-quality, science-backed carbon credits.
                        </p>

                        <h4 className="h4 mb-6">
                            Varaha has already made significant strides,
                            enrolling over 700,000 acres of land across India,
                            Bangladesh, Nepal, and Kenya. It works with more
                            than 100 partners across these geographies to
                            onboard smallholder farmers, with plans to increase
                            this number by 4X in the next 12-15 months.
                        </h4>

                        <p className="mb-6">
                            Varaha has already contracted and sold more than
                            230,000 carbon credits across a diversified project
                            portfolio. Varaha’s customers include some of the
                            largest marketplaces in the world, such as Klimate
                            in Denmark, Good Carbon in Germany, Carbon Future in
                            Switzerland, top-tier companies in Europe, with
                            strong interest also coming from financial
                            institutions and tech companies across the UK and
                            US.
                        </p>

                        <p className="mb-6">
                            Beyond regenerative agriculture and afforestation,
                            Varaha is also converting agricultural residue into
                            biochar, a material that locks carbon away
                            permanently for thousands of years while enhancing
                            soil fertility. Varaha is further exploring the
                            potential of Enhanced Rock Weathering, an exciting
                            method that boosts soil fertility and sequesters
                            atmospheric carbon long-term.
                        </p>

                        <h4 className="h4 mb-2">
                            "With nearly 24% of global greenhouse gas emissions
                            stemming from the agricultural sector, Varaha's
                            mission to sequester and reduce carbon emissions is
                            more critical than ever,"
                        </h4>
                        <p className="mb-6">
                            — Madhur Jain, Co-Founder and CEO of Varaha
                        </p>

                        <p className="mb-6">
                            "Today's funding is a testament to our commitment to
                            drive impactful change. With the support of our
                            investors, we're poised to roll up our sleeves and
                            accelerate our efforts, empower smallholders,
                            integrate cutting-edge technology, and foster
                            sustainable practices. Varaha isn't just a company;
                            we're a catalyst for change, and this funding
                            catapults us towards a greener, more equitable
                            future."
                        </p>
                        <p className="mb-6">
                            Galina Chifina, Partner on RTP Global’s Asia
                            investment team said, “We are thrilled to be part of
                            Varaha’s journey towards creating scalable solutions
                            for climate resilience. The visionary and unique
                            approach of Madhur and his team to generating carbon
                            credits through nature-based solutions has the
                            potential to become a game-changer for the industry!
                            It’s been a privilege to support them early in their
                            journey of building a truly impactful company that
                            is poised to make a significant difference on our
                            planet".
                        </p>

                        <p className="mb-6">
                            The funding raised will be channeled into further
                            enhancing Varaha’s tech and science capabilities and
                            expanding operations to new geographies in Asia and
                            Sub-Saharan Africa, to meet the increasing demand
                            for high-quality carbon credits. With the voluntary
                            carbon offset market projected to reach $250 billion
                            by 2050 as per Morgan Stanley estimates, Varaha is
                            ideally positioned to contribute significantly to
                            this growth, driving global climate action and
                            carbon reduction efforts.
                        </p>

                        <p className="mb-6">
                            The sizeable raise comes just a year after Varaha
                            previously raised a seed round of US$ 4 million. The
                            seed round was led by Orios Venture Partners, and
                            included participation from Omnivore, RTP Global,
                            Better Capital, and other angel investors like Kunal
                            Shah.
                        </p>

                        <p className="mb-6">
                            Varaha was founded in 2022 by Madhur Jain
                            (Co-Founder & CEO), Ankita Garg (Co-Founder & COO),
                            and Vishal Kuchanur (Co-Founder & CTO). With
                            backgrounds in agriculture, biotech and software,
                            the founders are committed to reducing agriculture's
                            carbon footprint and improving the livelihoods of
                            smallholder farmers.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

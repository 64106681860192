export const jobListing = [
    {
        title: 'Engineering',
        description: 'Sr. Software Developer - Backend',
        applicationUrl:
            'https://drive.google.com/file/d/1vCrUti8soSaV4byai9Qvnkl7vc9CnGfk/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 1,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Engineering',
        description: 'Machine Learning Engineer',
        applicationUrl:
            'https://drive.google.com/file/d/11wX_MdNwu1S_6aFkIMHxLPfoSNnZafP1/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 2,

        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Engineering',
        description: 'Geospatial Data Scientist',
        applicationUrl:
            'https://drive.google.com/file/d/1c6TBv0-uK1q32WVGVNkh_Gp1phhQzD3g/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 3,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description: 'Manager - Carbon Supply - Gujarat and Maharashtra',
        applicationUrl:
            'https://drive.google.com/file/d/16z6sLQN8CJO3FjquJ5a0o1fvw1COs0gW/view',
        subtitle: 'Hybrid',
        id: 4,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description: 'Manager - Carbon Supply - Uttar Pradesh and Tamil Nadu',
        applicationUrl:
            'https://drive.google.com/file/d/1WIKVbpoqnXP67yL3PHJqAlSOR8CMqskH/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 5,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Project',
        description: 'Head of Carbon Projects',
        applicationUrl:
            'https://drive.google.com/file/d/1kd9aTzz5QKaazr9sIqSmh3MQ7zMAGVYY/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 7,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Project',
        description: 'Sr. Technical manager Carbon Project',
        applicationUrl:
            'https://drive.google.com/file/d/14BVUZfPKyvOiNXh4w0OaB6CxqZAEjrH7/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 8,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Finance',
        description: 'Senior Manager – Strategy & Carbon Investment',
        applicationUrl:
            'https://drive.google.com/file/d/1PJJBHcanDDUBmndddWPc7J55yYYFZHL5/view?usp=drive_link',
        subtitle: 'Hybrid',
        id: 9,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Engineering',
        description: 'Data Engineer',
        applicationUrl:
            'https://docs.google.com/document/d/1NjThM1kSktn-hhjBFwIWADMioAfbFrCW',
        subtitle: 'Hybrid',
        id: 10,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Engineering',
        description: 'Full Stack Developer',
        applicationUrl:
            'https://docs.google.com/document/d/1CxFSsE-ye0P8mvFroaC2hWOLNpZDHboO',
        subtitle: 'Hybrid',
        id: 12,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Engineering',
        description: 'Mobile Engineer',
        applicationUrl:
            'https://docs.google.com/document/d/1aoPvPnLt0BD0NfnMr0bg6A_hdNCs6ujT',
        subtitle: 'Hybrid',
        id: 13,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Operations',
        description: 'Associate-Founders office',
        applicationUrl:
            'https://drive.google.com/file/d/15l_NVwp8Fgiw8Qucy03YvVklPb00HaoP/view?usp=drive_link',
        subtitle: 'Gurugram,Haryana',
        id: 14,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Research & Dev',
        description: 'Research Associate (Modeling)',
        applicationUrl:
            'https://drive.google.com/file/d/19yN_olsM4qF6z85TKPtvn8a-FWU-Rd9r/view',
        subtitle: 'Gurugram,Haryana',
        id: 15,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Research & Dev',
        description: 'Project Associate',
        applicationUrl:
            'https://drive.google.com/file/d/1gQh3V-oAz-DVkPGlv5o2z1bCV0fF2NaY/view?usp=sharing',
        subtitle: 'Gurugram,Haryana',
        id: 16,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Project',
        description: 'Associate Manager',
        applicationUrl:
            'https://drive.google.com/file/d/1ryaBEeffz4_1UVInws7KvWPQ30YCdORq/view?usp=sharing',
        subtitle: 'Gurugram, Haryana',
        id: 18,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description: 'Director - East Africa',
        applicationUrl:
            'https://drive.google.com/file/d/1wzx-pyGGV_Pq4NrlNVcHeHQjZMwN4J2t/view?usp=sharing',
        subtitle: 'Anywhere in East Africa (Preferred: Kenya)',
        id: 19,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description: 'Country Manager',
        applicationUrl:
            'https://drive.google.com/file/d/1kqGqQOSIdaqDEwiyn03wVwXExKWcWCt2',
        subtitle: 'Nairobi, Kenya.',
        id: 20,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description:
            'Business Developer - Regenerative Agriculture & Insetting, Europe',
        applicationUrl:
            'https://drive.google.com/file/d/1Fj8V2BqlpRYqDOKWERVRDaJzqux6Fcg4/view',
        subtitle: 'Europe, Anywhere',
        id: 21,
        googleForm:
            'https://docs.google.com/forms/d/e/1FAIpQLSeiPbVaqIIgCoXbCJkL2OwRuwlQz4v2zTtWvdNUyWpUx4YEhg/viewform',
    },
    {
        title: 'Carbon Supply',
        description: 'Director Business Development - North America',
        applicationUrl:
            'https://drive.google.com/file/d/1fd0Xx88IbA7GAdKU5T7-pQRrz9o2YX2i/view',
        subtitle:
            'Ideally New York City or Bay Area, US, but we are open to other locations on the east and west coasts of the USA',
        id: 22,
        googleForm: 'https://forms.gle/XUZMRbZoV1tUFGAs6',
    },
];

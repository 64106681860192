import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    GeographicalFocus,
} from '../reusables';
import { southIndiaSdg } from '../../config/config';
import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import community from '@ourProjectAssets/stats/community.svg';
import farmer from '@ourProjectAssets/stats/farmer.svg';
import acre from '@ourProjectAssets/stats/acre.svg';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
import { useEffect } from 'react';
function AgroSouth() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner
                heading={
                    <>
                        Enhancing Livelihood Of Tribal Communities Through ARR
                        In South India
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative sm:left-2"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:relative sm:bottom-1">
                            India  |  5 States |  75,000 Hectares
                        </h2>
                    </div>
                }
                thumbnail={CDN_URL + '/ourProjects/project_06/06.png'}
                mediaType="image"
                pb="20"
                reverseHeading={true}
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'In Southern India, our ARR project is being implemented in Andhra Pradesh, Telangana, Tamil Nadu, Kerala and Karnataka to promote the plantation of native trees including fruit and nut trees like Cashew, Mango, Guava, Citrus, Oil Palm. '
                }
                paragraph2={
                    'An integrated and holistic developmental approach, that builds local resources and skills to improve agriculture and Non-Timber Forest Produce (NTFP), is being adopted to address the issue of tribal poverty and build their economic backbone.'
                }
                paragraph3={
                    ' The project activity will lead to considerable carbon sequestration in the areas, preserve topsoil run-offs during heavy rains, and lead to a considerable increase in the income of the farmers. All credits generated from this project are classified as removal credits. Starting in 2020, considered the baseline year, the project initially covered 3,500 hectares and is projected to expand to 75,000 hectares by 2029, maintaining this area until 2040. The project has a 20-year crediting period, with the possibility of renewal.'
                }
                id={4043}
                projectType={
                    <>
                        Agriculture Forestry and <br />
                        Other Land Use (ARR)
                    </>
                }
                region={'Southern India'}
                methodology={'AR-ACM0003'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/southIndia.png',
                    altText: 'south_india',
                }}
                region={'India'}
                imageClassName="w-[35%] h-5/6 lg:ml-40"
            />
            <ImplementationPartners
                title={'Implementation Partners'}
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/assist.png',
                        altText: 'assist.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-72 xl:h-72',

                        padding: '',
                    },
                ]}
            />
            <VarahaImpact
                carousal={true}
                contents={southIndiaSdg}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 12 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={[
                    '001',
                    '002',
                    '003',
                    '004',
                    '005',
                    '006',
                    '007',
                    '008',
                    '009',
                    '010',
                    '011',
                    '012',
                    '013',
                    '015',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_06/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + 'jpg',
                }))}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: farmer,
                        altText: 'farmer.svg',
                        start: 4789,
                        end: 5789,
                        unit: '',
                        description: 'Onboarded Farmers',
                        title: '',
                    },
                    {
                        imageUrl: acre,
                        altText: 'community.svg',
                        start: 20252,
                        end: 21252,
                        unit: '',
                        description: 'Acres Onboarded',
                        title: '',
                    },
                    {
                        imageUrl: community,
                        altText: 'sustainablePractice.svg',
                        start: 56000,
                        end: 57000,
                        unit: '',
                        description: 'tonnes of carbon sequestered',
                    },
                ]}
            />
        </>
    );
}

export default AgroSouth;

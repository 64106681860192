import React from 'react';
import './practices.css';
import pattern from '../../../assets/careerpage/pattern-green-repeat 1.svg';

const Practices = () => {
    return (
        <>
            <section className="bg-primary-light blade-padding-lg pb-20">
                <div className="container">
                    <h2 className="h3 max-w-screen-md mx-auto text-center color-primary font-medium">
                        Dedicated end-to-end assistance to help you confidently
                        invest in carbon-neutral nature-based practices{' '}
                    </h2>
                    <div className="pactices_cards pt-10">
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Expert Guidance</h5>
                            <p>
                                A team well-versed with the ins and outs of
                                carbon markets is here to guarantee hassle-free
                                access to high-quality carbon credits.
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Unparalleled Data Access</h5>
                            <p>
                                Trace, track, monitor, and audit data through an
                                all-encompassing seamless portal.
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Bespoke Project Portfolio</h5>
                            <p>
                                Curate a list of carefully-vetted nature-based
                                projects that are poised to have a positive
                                impact on the smallholder communities, the
                                climate, and the biodiversity
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Practices;

import { useEffect } from 'react';
import {
    ProjectCanvas,
    ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    GeographicalFocus,
} from '../reusables';
import { sdgs } from '../../config/config';
import community from '@ourProjectAssets/stats/community.svg';
import sustainablePractice from '@ourProjectAssets/stats/sustainable-practice.svg';
import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import water from '@ourProjectAssets/stats/water.svg';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function RegenGujarat() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        Cotton-focused Regenerative Agriculture Project in
                        Gujarat
                    </>
                }
                subHeading={
                    <div className="flex  items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative sm:left-2"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:relative sm:bottom-1">
                            India  |  1 State  |  312000 Hectares
                        </h2>
                    </div>
                }
                // videoLink={CDN_URL + '/ourProjects/project_05/05.png'}
                thumbnail={CDN_URL + '/ourProjects/project_05/05.png'}
                pb="20"
                reverseHeading={true}
                mediaType="image"
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    "Varaha's Carbon Farming Program is focused on expanding regenerative agricultural practices including drip irrigation, natural/organic farming, and effective fertilizer management/organic amendments, crop residue management."
                }
                paragraph2={
                    'A significant portion of the credits generated by this project are removal credits. The initiative, which began in 2020 with 25,000 hectares, aims to extend to 312,000 hectares by 2040, over a 20-year crediting period.'
                }
                paragraph3={
                    'Besides carbon removal and reduction, the project offers multiple benefits, such as improved soil organic content, reduced soil erosion, enhanced water quality, and increased climate resilience for smallholder farmers.'
                }
                id={4458}
                projectType={
                    <>
                        Agriculture Forestry and Other <br />
                        Land Use (AFOLU)
                    </>
                }
                region={'Gujarat, India'}
                methodology={'VM0042'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/gujarat.png',
                    altText: 'india_map',
                }}
                region={'Gujarat'}
                imageClassName="w-[50%] h-5/6 lg:ml-40"
            />
            <ImplementationPartners
                title={'Implementation Partners'}
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/akrsp.png',
                        altText: 'akrsp.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-4',

                        padding: '',
                        title: (
                            <>
                                Aga Khan Rural <br />
                                Support Programme
                            </>
                        ),
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/sanjevan.png',
                        altText: 'sanjevan.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-4',
                        padding: '',
                        title: <>Sajeevan</>,
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/ambuja.png',
                        altText: 'ambuja.png',
                        backgroundColor: '#FFB500',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-12',
                        padding: '',
                        title: (
                            <>
                                Ambuja Cement <br />
                                Foundation
                            </>
                        ),
                    },
                ]}
            />
            <VarahaImpact
                carousal={false}
                sdgs={sdgs}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 14 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={[
                    '001',
                    '002',
                    '003',
                    '004',
                    '005',
                    '006',
                    '007',
                    '008',
                    '009',
                    '010',
                    '011',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_05/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + 'jpg',
                }))}
            />
            {/* <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: water,
                        altText: 'water.svg',
                        start: 156500,
                        end: 157500,
                        unit: 'million liters',
                        description: 'million liters water saved',
                        title: 'million liters water saved',
                    },
                    {
                        imageUrl: community,
                        altText: 'community.svg',
                        start: 940230,
                        end: 941230,
                        unit: 'CO₂ eq.',
                        description: 'tonnes of carbon sequestered',
                        title: 'tonnes of carbon sequestered',
                    },
                    {
                        imageUrl: sustainablePractice,
                        altText: 'sustainablePractice.svg',
                        title: '99,465 tonnes',
                        start: 98465,
                        end: 99465,
                        unit: 'tonnes',
                        description: 'of crop residue incorporated',
                        title: 'of crop residue incorporated',
                    },
                ]}
            /> */}
        </div>
    );
}

export default RegenGujarat;

import React from 'react';
import './masterBtn.css';
import { useNavigate } from 'react-router-dom';

export default function Button({
    title,
    className,
    reverse,
    href,
    type,
    isloading,
    setIsloading,
    gradientId = 'button1',
    to,
    id,
}) {
    const navigate = useNavigate();
    const handleClick = (href) => {
        if (to) {
            navigate(to, { state: { scrollToElementId: id } });
            return;
        }
        if (href) {
            window.open(href, '_blank');
        }
    };

    return (
        <>
            <button
                type={type}
                onClick={() => handleClick(href)}
                className={`${className} ${
                    reverse ? 'btn-clr-green' : 'btn-clr-cream'
                } btn-style`}
            >
                <div className="arrow-wrapper">
                    <div className="arrow">
                        {!isloading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29"
                                height="27"
                                fill="none"
                                viewBox="0 0 29 27"
                            >
                                {/* a unique gradient id should be given while using the this component */}
                                <circle
                                    cx="16"
                                    cy="13"
                                    r="12"
                                    stroke={`url(#paint0_linear_${
                                        '539_84_' + gradientId
                                    })`}
                                    strokeWidth="2"
                                    transform="rotate(-90 16 13)"
                                ></circle>
                                <path
                                    fill="#004345"
                                    id="id-svg-arrow"
                                    d="M2 12a1 1 0 100 2v-2zm17.707 1.707a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.414 1.414L17.586 13l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM2 14h17v-2H2v2z"
                                ></path>
                                <defs>
                                    <linearGradient
                                        id={
                                            'paint0_linear_539_84_' + gradientId
                                        }
                                        x1="24.462"
                                        x2="24.462"
                                        y1="24.945"
                                        y2="5.945"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#006369"></stop>
                                        <stop
                                            offset="1"
                                            stopColor="#006369"
                                            stopOpacity="0"
                                        ></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {title ? <h4 className="px-4 font-medium">{title}</h4> : ''}
            </button>
        </>
    );
}

import ourSuiteimgOne from '@homeAssets/our-suite-end-to-end-project.png';
import ourSuiteimgTwo from '@homeAssets/our-suite-saas-based.png';
import ourSuiteimgThree from '@homeAssets/our-suite-access-hq-carbon-credits.png';

import creditQualityImgOne from '@businessAssets/credit-quality-one.png';
import creditQualityImgTwo from '@businessAssets/credit-quality-two.png';
import creditQualityImgThree from '@businessAssets/credit-quality-three.png';

//Hompage data our suite tabbed pannel

export const ourSuite = [
    {
        title: 'End-to-end Project Development',
        li1: 'Championing partnerships among landowners, investors, NGOs, businesses, and community groups to identify and support carbon removal from nature-based projects.',
        li2: 'Conserving, restoring, and managing natural ecosystems like forests, farmlands, agroforests, mangroves, grasslands, wetlands, etc.',
        li3: 'Ensuring immeasurable benefits like complementary synergies, a global bird’s eye view, and easy access to carbon markets.',
        src: ourSuiteimgOne,
    },
    {
        title: 'SaaS-based Digital(D) - MRV',
        li1: 'An end-to-end climate tech platform equipped with remote sensing, carbon modelling, and in-house proprietary Carbon Quantification Tool (CQT).',
        li2: 'Driving cheaper and more accurate partner enrollment, GHG quantification, measurement, reporting, and verification of projects and carbon credits at scale.',
        li3: 'Ensuring utmost efficiency and transparency at every step of carbon credit creation.',
        src: ourSuiteimgTwo,
    },
    {
        title: 'Access to high-quality Carbon Credits',
        li1: 'Enabling net-zero leaders to purchase high-quality carbon credits by making them choose from rigorously vetted nature-based projects.',
        li2: 'Cutting-edge technology adoption to continuously monitor projects over time and ensure longevity in investments. ',
        li3: 'A seamless investment mechanism for enabling companies to reach their respective climate goals faster than other traditional methods.',
        src: ourSuiteimgThree,
    },
];

// For-Business page data for Carbon Credit Quality

export const creditQuality = [
    {
        title: 'Reduction + Removal Approach',
        li1: 'We are not just reducing carbon from the agricultural/plantation farms but also drawing down and sequestering carbon back into the soil.    ',
        src: creditQualityImgOne,
    },
    {
        title: 'Additionality',
        li1: 'Our process of carbon offsetting is guaranteeing environmental benefits by making sure that the carbon credits generated from projects are involuntarily additional to the business-as-usual scenario.',
        src: creditQualityImgTwo,
    },
    {
        title: 'Data-backed, Secured & Transparent',
        li1: 'Projects are meticulously vetted through advanced remote sensing analytics and carbon modelling',
        li2: 'Our projects are registered with the world’s most recognized registries such as Verra.',
        li3: 'Tech-enabled SaaS tool for data hashing and transparent third-party audit process.',
        src: creditQualityImgThree,
    },
];

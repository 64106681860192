import React from 'react';
import './bannerSec.css';

export default function BannerSec({
    heading,
    videoLink,
    thumbnail,
    fullScreen,
}) {
    return (
        <div className={`h-fit banner-sec-wrapper`}>
            <video
                autoPlay
                loop
                muted
                className={`object-cover md:h-screen w-full ${
                    fullScreen ? 'h-screen' : 'h-[40rem]'
                }`}
                poster={thumbnail}
            >
                <source src={videoLink} type="video/mp4"></source>
            </video>

            <div className="banner-text-wrapper">
                <div className="w-11/12 md:w-10/12 mt-20 mx-auto h-3/6 flex items-end pb-3">
                    <h1 className="text-white xl:max-w-5xl max-w-3xl h1 fw-400 px-4 leading-tight font-medium">
                        {heading}
                    </h1>
                </div>
            </div>
        </div>
    );
}

import { useState } from 'react';
import { SdgCard } from '../../../../../modules/atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { CustomSlider } from '../../../../../modules/atoms';
import 'swiper/css';
import Image from '../../../../../assets/ourProjects/impact_background.svg';
import { useLocation } from 'react-router-dom';
function VarahaImpact({
    carousal = false,
    contents,
    title,
    subtitle,
    darkArrows = false,
    sdgs,
    projectName,
}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();
    return (
        <section
            className="flex flex-col gap-[3rem] 2xl:gap-[5rem] bg-primary-dark pt-16 pb-32 pl-8  sm:pl-[12rem] sm:pt-[9.5rem] sm:pb-20 relative"
            style={
                location.pathname === '/ourProjects/erw-madhya-pardesh'
                    ? {}
                    : {
                          backgroundImage: `url(${Image})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                      }
            }
        >
            <div className="flex flex-col">
                <h1 className="h2 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                    {title}
                </h1>
                <h2 className="h4 text-white">{subtitle}</h2>
            </div>
            {carousal ? (
                <div className="flex flex-col gap-[1.69rem] 2xl:gap-[2.69rem]">
                    <div className="w-[99%] sm:w-[100%]">
                        <Swiper
                            autoHeight={true}
                            speed={500}
                            navigation={{
                                nextEl: '.impact-next-btn',
                                prevEl: '.impact-prev-btn',
                                disabledClass: 'swiper-button-disabled',
                            }}
                            autoplay={{ delay: 5000 }}
                            creativeEffect={{
                                limitProgress: 3,
                                prev: {
                                    shadow: false,
                                    translate: ['-100%', 0, 0],
                                },
                                next: {
                                    translate: ['100%', 0, 0],
                                },
                            }}
                            onSlideChange={(swiper) => {
                                setActiveIndex(swiper.realIndex);
                            }}
                            modules={[Navigation, Pagination, Autoplay]}
                            spaceBetween={25}
                            effect={'creative'}
                            loop={true}
                            slidesPerView={'auto'}
                            className="mySwiper"
                        >
                            {contents.map((el, index) => (
                                <SwiperSlide
                                    className="flex basis-[17.5rem] md:basis-[24rem]"
                                    key={`0${index + 1}_sdg`}
                                >
                                    <SdgCard
                                        title={el.title}
                                        subtitle={el.subtitle}
                                        backgroundImage={el.backgroundImage}
                                        sdg={el.sdg}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div className="flex w-[60%] md:w-[80%] lg:w-[40%] items-center gap-2">
                        <CustomSlider
                            darkArrows={darkArrows}
                            buttonSize={'w-6 h-6'}
                            showPageIndex={false}
                            carousalLength={contents.length}
                            activeIndex={activeIndex}
                            prevBtnClass={'impact-prev-btn'}
                            nextButtonClass={'impact-next-btn'}
                            progressBarClass={
                                'bg-primarylight h-1 overflow-visible w-full rounded-lg relative '
                            }
                        />
                    </div>
                </div>
            ) : (
                <div className="flex flex-wrap gap-16 2xl:gap-24 justify-center sm:justify-start">
                    {sdgs.map((e, index) => (
                        <img
                            src={e}
                            alt={`${index}-sdg`}
                            key={`${index}_sdg`}
                            className="w-44 h-44 rounded-xl"
                        />
                    ))}
                </div>
            )}
        </section>
    );
}

export default VarahaImpact;
// basis-[20.5rem] 2xl:

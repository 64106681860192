import React, { useEffect } from 'react';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '@iconsAssets/varaha-chakra.svg';
import './error.css';

export default function Error404Screen() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="errorScreenWrapper flex justify-center items-center text-primarylight  bg-primary">
            <h3 className="md:text-[7rem] text-[3rem] font-extrabold text-center">
                Error 4
                <div className="md:w-[6.2rem] text-[3rem] inline-block md:pl-2 pl-1">
                    <img className="w-full h-full" src={logo} alt="" />
                </div>
                4
            </h3>
            <h5 className="h5 font-normal md:mt-4 mt-2 px-3">
                The requested page may have been removed.
            </h5>
            <Link
                to="/"
                className="p mt-9 px-5 py-3 m-2 br-2 flex text-yellow hover:text-primarylight items-center border-2 border-yellow hover:border-primarylight rounded-lg transition-all duration-300"
            >
                <FaHome className="h6 mr-2" /> Go to Homepage
            </Link>
        </div>
    );
}

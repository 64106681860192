import React, { useState } from 'react';
import './decarbonizing.css';
import { ImCross } from 'react-icons/im';

export default function Decarbonizing() {
    const [isShow, setIsShow] = useState(false);

    return (
        <section className="decarbonizing-wrapper blade-padding-lg bg-primary-dark pb-20">
            <div className="container">
                <div className="xl:max-w-screen-md lg:max-w-screen-sm">
                    <h2
                        className="h3 text-gradient font-medium"
                        data-aos="fade-up"
                    >
                        Decarbonizing the atmosphere while ensuring longevity of
                        investments in emission-capturing natural projects
                    </h2>

                    <div className="py-4">
                        <ul className="decarbonizing-paragraph track-decorbonize z-0 md:mr-10 mr-4">
                            <li
                                className="h5 text-white leading-normal font-extralight my-5"
                                data-aos="fade-up"
                            >
                                <span className="font-semibold">
                                    Breathing through the mission of changing
                                    the status quo,{' '}
                                </span>
                                Varaha is a pioneering climate tech startup
                                focused upon creating high-integrity carbon
                                credits from nature based solutions such as
                                regenerative agriculture, ARR and Biochar.
                            </li>
                            <li
                                className="h5 text-white leading-normal font-extralight my-5 relative"
                                data-aos="fade-up"
                            >
                                Connecting these carbon removal projects with
                                companies and helping them address their net
                                zero emission targets, we provide end-to-end
                                assistance by being a one-stop destination for
                                the creation, quantification, verification, &
                                sale of high-quality{' '}
                                <h5
                                    onMouseEnter={() =>
                                        isShow
                                            ? setIsShow(false)
                                            : setIsShow(true)
                                    }
                                    onMouseLeave={() =>
                                        isShow
                                            ? setIsShow(false)
                                            : setIsShow(true)
                                    }
                                    className="h5 font-bold text-cyan cursor-pointer font-souvenir lg:inline-block hidden"
                                >
                                    carbon-credits*
                                </h5>
                                <h5
                                    onClick={() =>
                                        isShow
                                            ? setIsShow(false)
                                            : setIsShow(true)
                                    }
                                    className="h5 font-bold k text-cyan cursor-pointer font-souvenir inline-block lg:hidden"
                                >
                                    carbon-credits*
                                </h5>
                                .
                            </li>
                            <li
                                className="h5 text-white leading-normal font-extralight my-5 relative"
                                data-aos="fade-up"
                            >
                                Each project is meticulously vetted through
                                advanced remote sensing analytics and carbon
                                modelling ensuring every investment results in
                                carbon removal and creates an additional income
                                source for the smallholders along the way.
                            </li>
                        </ul>
                    </div>

                    <div
                        className={` ${
                            isShow
                                ? 'opacity-100 z-1 block'
                                : 'opacity-0 hidden -z-[999]'
                        } popup-wrapper h-screen transition-all duration-500 fixed top-0 right-0`}
                    >
                        <div
                            className={`${
                                isShow ? 'translate-x-0' : 'translate-x-full'
                            }
                popup-card bg-primary shadow-xl relative max-w-xl p-8 rounded-xl md:mx-20 mx-auto transition-all duration-500 pt-40`}
                        >
                            <div
                                onClick={() => {
                                    setIsShow(false);
                                }}
                                className="absolute top-8 cursor-pointer right-8 w-12 h-12 rounded-full border-2 border-white place-content-center grid lg:hidden "
                            >
                                <ImCross className=" text-white text-lg" />
                            </div>

                            <h2 className="h2 text-white font-souvenir leading-snug">
                                Carbon <br /> Credits
                            </h2>

                            <p className="h5 text-white sm:mt-5">
                                Carbon Credits represent quantities of
                                greenhouse gases that have been sequestered or
                                kept out of the atmosphere as a result of
                                regenerative agricultural/forestry practices.
                                Purchasing them is a way for a company to
                                address emissions it cannot eliminate.
                                Numerically speaking, 1 tonne of CO
                                <sub>2</sub> reduction or removal equals 1
                                carbon credit.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

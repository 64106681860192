import React from 'react';
import HubspotContactForm from './HubspotContactForm';
import { IoMdAdd } from 'react-icons/io';

export default function ContactHubSpot({ handleClose, show, id }) {
    const showHideClassName = show
        ? 'contact-modal color-dark flex'
        : ' contact-modal color-dark hidden';
    return (
        <div className={showHideClassName}>
            <div className="contact-wrapper">
                <button type="button" className="close" onClick={handleClose}>
                    <IoMdAdd />
                </button>
                <div className="grid grid-cols-3">
                    <div className="contact-header p-8 flex justify-between flex-col h-full">
                        <h2 className="h2 text-white">Reach out to us</h2>
                        {/* <p className='text-white hidden md:block'>let’s start a conversation,
                            email us at <a href="mailto:info@varahaag.com">info@varahaag.com</a></p> */}
                    </div>
                    <div className="col-span-2 p-8">
                        <HubspotContactForm
                            region="na1"
                            portalId="39899394"
                            formId="ac4ee496-c1e3-48c0-8cd1-bff47c2f58b5"
                            id={id}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

/**
 *  hbspt.forms.create({
    region: "na1",
    portalId: "39899394",
    formId: "ac4ee496-c1e3-48c0-8cd1-bff47c2f58b5"
  });
 */

import React from 'react';
import './crisis.css';
import crisisTiles from '@aboutAssets/crisisTiles.png';
export default function Crisis() {
    return (
        <section className="climate-crisis-wrapper bg-primary-dark">
            <div className="max-w-2xl relative  blade-padding-lg text-center mx-auto  text-wrapper bg-clip-text text-transparent">
                <h1 className=" text-4xl md:text-5xl lg:text-6xl xl:text-7xl px-7 font-medium">
                    Is the climate crisis solvable?
                </h1>
            </div>
            <div className="blade-padding-lg max-w-screen-xl md:w-11/12  mx-auto">
                <img
                    src={crisisTiles}
                    alt="crisis tiles"
                    className="h-full w-full object-cover object-center min-h-[150px]"
                />
            </div>
        </section>
    );
}

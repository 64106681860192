import React, { useEffect } from 'react';
import { Banner } from '../../modules/molecules';
import Stats from './stats';
import Quote from './quote';
import Stages from './stages';
import Practices from './practices';
import Guidelines from './guideline';
import OnBoardingCards from './onBoardingCards';
import NetZeroCarousel from './netZeroCarousel';
import thumbnail from '@thumbnailsAssets/partners.jpg';
import { CDN_URL } from '../../config';
import Faqs from './faqs';

const ForBusiness = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                heading="Where we pay you to grow sustainably"
                subHeading="Incentivized carbon programs for farmers, agroforestry, and forestry practitioners"
                videoLink={CDN_URL + '/for_partners.mp4'}
                pb="20"
                thumbnail={thumbnail}
            />

            <Stats />

            <Quote />

            <Stages />

            <Practices />

            <Guidelines />

            <OnBoardingCards />

            <NetZeroCarousel />

            <Faqs />
        </>
    );
};
export default ForBusiness;

import { useEffect } from 'react';
import {
    ProjectCanvas,
    // ImplementationPartners,
    VarahaImpact,
    VisualDiary,
    ProjectImpact,
    GeographicalFocus,
} from '../reusables';
import { sdgs } from '../../config/config';
import community from '@ourProjectAssets/stats/community.svg';
import sustainablePractice from '@ourProjectAssets/stats/sustainable-practice.svg';
import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import water from '@ourProjectAssets/stats/water.svg';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function BioChar() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        Durable Carbon Removal along with
                        <br /> Restoration Of Pastoral And Community <br />
                        Lands
                    </>
                }
                subHeading={
                    <div className="flex  items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative sm:left-2"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:relative sm:bottom-1">
                            India  |  20,000 annual ERs &nbsp;(100% removal)
                        </h2>
                    </div>
                }
                thumbnail={CDN_URL + '/ourProjects/project_04/04.png'}
                pb="20"
                reverseHeading={true}
                mediaType="image"
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    "Varaha's Biochar Project is an innovative initiative aimed at combating the invasion of Prosopis juliflora in India's Gujarat and Rajasthan states, including the Banni Grasslands. This tree species, thriving in arid conditions, not only disrupts local ecosystems by depleting groundwater and suppressing native flora but also affects local communities."
                }
                paragraph2={
                    'By converting the invasive Prosopis into biochar, a carbon-rich product, the project integrates advanced technology and ethical practices to offer a sustainable solution for carbon capture and soil enrichment.'
                }
                paragraph3={
                    'Initially piloted in the Banni Grassland, this approach is now expanding across Gujarat and Rajasthan, demonstrating its potential in environmental and social benefits.'
                }
                id={'-'}
                projectType={'Artisanal Biochar'}
                region={'Rajastan and Gujarat,India'}
                methodology={'Carbon Standard International Artisan Pro'}
                yearNumber={2023}
                year={true}
                methodologyTitle={'Registry'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/biochar_gujarat.png',
                    altText: 'india_map',
                }}
                region={'India'}
                imageClassName="w-[35%] h-5/6 lg:ml-40"
            />
            {/* <ImplementationPartners
                title={'Implementation Partners'}
                images={[
                    {
                        src: CDN_URL + '/ourProjects/partners/sanjevan.png',
                        altText: 'sanjevan.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-4',
                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/ambuja.png',
                        altText: 'ambuja.png',
                        backgroundColor: '#FFB500',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-12',
                        padding: '',
                    },
                    {
                        src: CDN_URL + '/ourProjects/partners/akrsp.png',
                        altText: 'akrsp.png',
                        backgroundColor: 'white',
                        widthStyle:
                            'w-60 h-60 sm:w-36 sm:h-36 md:w-48 md:h-48 xl:w-64 xl:h-64 p-4',

                        padding: '',
                    },
                ]}
            /> */}
            <VarahaImpact
                carousal={false}
                sdgs={sdgs}
                title={'Varaha’s Impact Beyond Carbon'}
                subtitle={
                    'Contributing to the 14 Sustainability Development Goals (SDGs) India Index.'
                }
            />
            <VisualDiary
                images={[
                    '002',
                    '001',
                    '003',
                    '004',
                    // '005',
                    // '006',
                    '007',
                    '008',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_04/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + '.jpg',
                }))}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    // {
                    //     imageUrl: water,
                    //     altText: 'water.svg',
                    //     start: 156500,
                    //     end: 157500,
                    //     unit: 'million liters',
                    //     description: 'million liters water saved',
                    //     title: 'million liters water saved',
                    // },
                    {
                        imageUrl: community,
                        altText: 'community.svg',
                        start: 4000,
                        end: 5000,
                        unit: '',
                        description: 'tonnes of carbon removed',
                        title: 'tonnes of carbon removed',
                    },
                    // {
                    //     imageUrl: sustainablePractice,
                    //     altText: 'sustainablePractice.svg',
                    //     title: '99,465 tonnes',
                    //     start: 98465,
                    //     end: 99465,
                    //     unit: 'tonnes',
                    //     description: 'of crop residue incorporated',
                    //     title: 'of crop residue incorporated',
                    // },
                ]}
            />
        </div>
    );
}

export default BioChar;

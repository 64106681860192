import Banner from '../../modules/molecules/banner';
import thumbnail from '@thumbnailsAssets/ourprojects.jpg';
import ProjectList from './projectList';
import { useEffect } from 'react';
function OurProjects() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="overflow-hidden bg-[#004d4a]">
            <Banner
                heading="Planetary Scale Climate Change Mitigation"
                subHeading={
                    <>
                        Varaha is leading the Charge in Environmental
                        Stewardship with its
                        <span className="text-yellow font-semibold">
                            {' '}
                            High-Quality
                        </span>
                        ,
                        <span className="text-yellow font-semibold">
                            High-Integrity{' '}
                        </span>
                        <br />
                        Carbon Projects.
                    </>
                }
                videoLink="https://dfrlze9c0ze95.cloudfront.net/ourprojects.mp4"
                thumbnail={thumbnail}
                pb="20"
            />

            <ProjectList />
        </section>
    );
}

export default OurProjects;

//Generic Custom Card which can be customized based based on image size and also
//customize project card description based on flex direction()
/**
 * Generic Custom Card which can be customized based based on image size and also
customize project card description based on flex direction(
    row:(align content to left/right/center)
    column:(align content to top/bottom/center)
)
 */
const GenericProjectCard = ({
    projectImage,
    cardContainer,
    justifyDiscription,
    subTitle,
    projectNumber,
}) => {
    return (
        <article
            className={`flex ${cardContainer} w-full ${justifyDiscription}`}
            style={{
                backgroundImage: ` url(${projectImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '60% 75%',
                height: '30.75rem',
            }}
        >
            <div className="pl-[1.5rem] pb-[1.5rem]">
                <span className="opacity-70 text-white h5 font-medium">
                    {projectNumber}
                    <span className="opacity-70 text-white p font-medium">
                        /07
                    </span>
                </span>
                <span className="opacity-70">{subTitle}</span>
            </div>
        </article>
    );
};

export default GenericProjectCard;

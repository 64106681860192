import React from 'react';
import './define.css';
import pattern from '../../../assets/careerpage/pattern-green-repeat 1.svg';

const Define = () => {
    return (
        <>
            <section className="define_container">
                <div className=" ">
                    <h2 className="h2 text-center color-primary font-medium">
                        What defines us?
                    </h2>
                    <div className="define_cards">
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Inclusive Culture</h5>
                            <p>
                                We house a diverse group of individuals
                                featuring Engineers, Carbon Market Experts,
                                Scientists, ex-Founders, Sustainability Leaders,
                                etc.
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Growth-Oriented</h5>
                            <p>
                                A great learning ground for folks from all
                                backgrounds.
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>Passion for Excellence</h5>
                            <p>
                                Quality-centered, Trust-centered, &
                                Customer-centered in solving even the pressing
                                problems.
                            </p>
                        </div>
                        <div className="cards">
                            <img src={pattern} alt="" />
                            <h5>The Right Backing</h5>
                            <p>
                                Processes, Tools, & Outcomes, everything is
                                science/data-backed.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Define;

import { useEffect } from 'react';
import {
    ProjectCanvas,
    GeographicalFocus,
    VisualDiary,
    ProjectImpact,
} from '../reusables';
import community from '@ourProjectAssets/stats/community.svg';

import indiaFlag from '@ourProjectAssets/flags/india_flag.png';
import { Banner } from '../../../../modules/molecules';
import { CDN_URL } from '../../../../config';
function PuroTelangana() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="overflow-hidden">
            <Banner
                heading={
                    <>
                        High-Tech Biochar Project in
                        <br />
                        Telangana, India
                    </>
                }
                subHeading={
                    <div className="flex items-baseline flex-wrap justify-center">
                        <img
                            src={indiaFlag}
                            alt="india_flag"
                            className="w-12 h-6 relative top-1"
                        />
                        <h2 className="h5 color-white md:mt-5 mt-2 text-center font-normal px-4 sm:bottom-1">
                            India |  1 State
                        </h2>
                    </div>
                }
                pb="20"
                reverseHeading={true}
                thumbnail={CDN_URL + '/ourProjects/project_08/banner.png'}
                mediaType="image"
            />
            <ProjectCanvas
                title={''}
                paragraph1={
                    'The project is based in a Maize seeds processing facility that utilises waste biomass from corn cobs and gasifies it for generating syngas which is utilized to provide heat for the drying process. This is a high tech facility undergoing accreditation from Puro.Earth.'
                }
                paragraph2={
                    'The project will remove more than 1,000 tonnes of CO₂ eq. each year while it is operational through the production of biochar.'
                }
                paragraph3={
                    'The pulverised biochar is provided to farmers in close proximity to the facility free of cost to be used as a soil amendment and for the improvement of soil health.'
                }
                // paragraph4={
                //     'Apart from removing CO₂ this facility will also avoid up to 4,000 tonnes of CO₂ emissions per year which would have inevitably occurred due to mistreatment of waste biomass.'
                // }
                paragraph5={
                    'This is a project with significant environmental potential should it be expanded to other facilities which we are at the initial stages of developing'
                }
                showYear={false}
                year={true}
                projectType={<> High Tech Biochar Pyrolysis</>}
                region={'Telangana,India'}
                methodology={'Puro'}
                methodologyTitle={'Registry'}
            />
            <GeographicalFocus
                image={{
                    imageUrl: CDN_URL + '/ourProjects/maps/Telangana.png',
                    altText: 'Telangana_map',
                }}
                region={'India'}
                imageClassName="w-[35%] h-5/6 lg:ml-40"
            />
            <VisualDiary
                images={[
                    '001',
                    '002',
                    '003',
                    '004',
                    '002',
                    '003',
                    '004',
                    '005',
                    '006',
                ].map((e) => ({
                    imageUrl:
                        CDN_URL +
                        '/ourProjects/project_08/visual_diary/' +
                        e +
                        '.jpg',
                    altText: e + '.jpg',
                }))}
            />
            <ProjectImpact
                title={'One  Project, Many Impacts'}
                contents={[
                    {
                        imageUrl: community,
                        altText: 'sustainablePractice.svg',
                        // start: 24000,
                        // end: 25000,
                        title: '25000 credits until 2025',
                        description: '3000 credits by November 2024',
                    },
                ]}
            />
        </div>
    );
}

export default PuroTelangana;

import { useEffect, useState } from 'react';
function Practices({ title, tabs, defaultTab = 0 }) {
    const [activeTab, setActiveTab] = useState(defaultTab);

    useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    return (
        <section className="pt-[2rem] sm:pt-[8.5rem] lg:pt-[12.5rem] bg-primary sm:bg-primarydark relative z-10 xl:min-h-screen pb-10">
            <h2
                className="h2 text-gradient text-center font-medium"
                // data-aos="fade-up"
            >
                {title}
            </h2>

            <div
                className="w-full tabbed-container blade-margin-xs sm:flex flex-col hidden lg:gap-16 xl:gap-20"
                role="tablist"
                aria-label="Top Footballers"
                data-aos="fade-up"
            >
                <div className="tabs-wrapper">
                    {tabs.map((tab, index) => {
                        const active = activeTab === index;
                        return (
                            <button
                                className={`${
                                    active ? 'activeTab' : ''
                                } h5 px-1`}
                                key={index}
                                role="tab"
                                aria-selected={active}
                                aria-controls={`panel-${index}`}
                                id={`tab-${index}`}
                                tabIndex={active ? 0 : -1}
                                onClick={() => setActiveTab(index)}
                            >
                                {tab.title}
                            </button>
                        );
                    })}
                </div>
                <section
                    key={`${activeTab}_practice`}
                    // role="tabpanel"
                    tabIndex="0"
                    aria-labelledby={`tab-${activeTab}`}
                    // hidden={activeTab !== index}
                    // data-aos="fade-up"
                    className="w-full flex justify-around min-h-[18.75rem] items-center"
                >
                    <div className="rounded-xl w-[35%] relative overflow-hidden">
                        <video
                            autoPlay
                            loop
                            muted
                            poster={tabs[activeTab].before?.thumbnail}
                            className="rounded-xl h-full border-[0.5rem] border-[#567C7C] w-full object-cover"
                        >
                            <source
                                src={tabs[activeTab].before?.videoLink}
                                type="video/mp4"
                            ></source>
                        </video>
                        <h3 className="absolute h4 bottom-8 left-8 text-white">
                            {tabs[activeTab].before?.title}
                        </h3>
                    </div>
                    <div className="rounded-xl w-[35%] relative overflow-hidden">
                        <video
                            autoPlay
                            loop
                            muted
                            poster={tabs[activeTab].after?.thumbnail}
                            className="rounded-xl border-[0.5rem] border-[#567C7C] w-full h-full object-cover"
                        >
                            <source
                                src={tabs[activeTab].after?.videoLink}
                                type="video/mp4"
                            ></source>
                        </video>
                        <h3 className="absolute h4  bottom-8 left-8 text-white">
                            {tabs[activeTab].after?.title}
                        </h3>
                    </div>
                </section>
            </div>
            <div className="block sm:hidden px-8 py-8">
                {tabs.map((e, index) => (
                    <div
                        className="mt-6 border-[0.68rem] border-[#3d706d] rounded-3xl"
                        key={`${index}-practices-video`}
                    >
                        <div className="flex flex-col  px-[3.4rem] py-[2rem] gap-8">
                            <h2
                                className="h5 text-[#F9AC00] font-medium text-center"
                                data-aos="fade-up"
                            >
                                {e.title}
                            </h2>
                            <div className="rounded-xl relative ">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    poster={e.before?.thumbnail}
                                    className="rounded-xl  border-[0.5rem] border-[#567C7C]"
                                >
                                    <source
                                        src={e.before?.videoLink}
                                        type="video/mp4"
                                    ></source>
                                </video>
                                <h3 className="absolute p bottom-4 sm:bottom-8 left-4 text-white font-bold">
                                    {e.before?.title}
                                </h3>
                            </div>
                            <div className="rounded-xl  relative">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    poster={e.after?.thumbnail}
                                    className="rounded-xl border-[0.5rem] border-[#567C7C]"
                                >
                                    <source
                                        src={e.after?.videoLink}
                                        type="video/mp4"
                                    ></source>
                                </video>
                                <h3 className="absolute p  bottom-4 sm:bottom-8 left-4 text-white font-bold">
                                    {e.after?.title}
                                </h3>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Practices;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function NavButton({
    title,
    className,
    reverse,
    link,
    isloading,
    type,
    darkBackground,
    svgFill = '#004345',
    hoverFillColor,
    gradientId = 'nav_button_1',
}) {
    const [fillColor, setFitColor] = useState(svgFill);
    if (type === 'smallButton') {
        return (
            <NavLink
                to={`${link}`}
                className={`${className} ${
                    reverse ? 'btn-clr-green' : 'btn-clr-cream'
                } btn-style flex justify-center w-auto`}
                onMouseEnter={() => {
                    if (hoverFillColor) setFitColor(hoverFillColor);
                }}
                onMouseLeave={() => {
                    setFitColor(svgFill);
                }}
            >
                <div className="arrow-wrapper">
                    <div className="arrow">
                        {!isloading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29"
                                height="27"
                                fill="none"
                                viewBox="0 0 29 27"
                            >
                                <circle
                                    cx="16"
                                    cy="13"
                                    r="12"
                                    stroke={`url(#paint0_linear_${
                                        '539_84_' + gradientId
                                    })`}
                                    strokeWidth="2"
                                    transform="rotate(-90 16 13)"
                                ></circle>

                                <path
                                    fill={fillColor}
                                    id="id-svg-arrow"
                                    d="M2 12a1 1 0 100 2v-2zm17.707 1.707a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.414 1.414L17.586 13l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM2 14h17v-2H2v2z"
                                ></path>
                                <defs>
                                    <linearGradient
                                        id={
                                            'paint0_linear_539_84_' + gradientId
                                        }
                                        x1="24.462"
                                        x2="24.462"
                                        y1="24.945"
                                        y2="5.945"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor={fillColor}></stop>
                                        <stop
                                            offset="1"
                                            stopColor={fillColor}
                                            stopOpacity="0"
                                        ></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <h4 className="px-4 font-medium text">{title}</h4>
            </NavLink>
        );
    }
    return (
        <>
            <NavLink
                to={`${link}`}
                className={`${className} ${
                    reverse ? 'btn-clr-green' : 'btn-clr-cream'
                } btn-style flex justify-center md:w-52 w-48`}
                onMouseEnter={() => {
                    if (hoverFillColor) setFitColor(hoverFillColor);
                }}
                onMouseLeave={() => {
                    setFitColor(svgFill);
                }}
            >
                <div className="arrow-wrapper">
                    <div className="arrow">
                        {!isloading ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="29"
                                height="27"
                                fill="none"
                                viewBox="0 0 29 27"
                            >
                                <circle
                                    cx="16"
                                    cy="13"
                                    r="12"
                                    stroke="url(#paint0_linear_539_84)"
                                    strokeWidth="2"
                                    transform="rotate(-90 16 13)"
                                ></circle>
                                <path
                                    fill={fillColor}
                                    id="id-svg-arrow"
                                    d="M2 12a1 1 0 100 2v-2zm17.707 1.707a1 1 0 000-1.414l-6.364-6.364a1 1 0 10-1.414 1.414L17.586 13l-5.657 5.657a1 1 0 001.414 1.414l6.364-6.364zM2 14h17v-2H2v2z"
                                ></path>
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_539_84"
                                        x1="24.462"
                                        x2="24.462"
                                        y1="24.945"
                                        y2="5.945"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor={fillColor}></stop>
                                        <stop
                                            offset="1"
                                            stopColor={fillColor}
                                            stopOpacity="0"
                                        ></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <h4 className="px-4 font-medium text">{title}</h4>
            </NavLink>
        </>
    );
}

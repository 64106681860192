import React from 'react';
import './creditQuality.css';

export default function MobileView({ data }) {
    return (
        <section className="sm:hidden block">
            <div className="">
                {data.map((tab, index) => (
                    <section
                        key={index}
                        className="mt-8 p-3 rounded-3xl glossy-grad bg-opacity-10"
                    >
                        <div className="bg-primary rounded-2xl">
                            <div className="left-wrapper ">
                                <div className="img-wrapper ">
                                    <img className="" src={tab.src} alt="" />
                                </div>
                            </div>

                            <div className="txt-wrapper p-4 ">
                                <h2 className="color-yellow h4 leading-tight">
                                    {tab.title}
                                </h2>
                                <ul className="track-oursuite relative">
                                    {tab.li1 ? (
                                        <li className="h6 text-white my-3 py-3 font-extralight leading-snug ">
                                            {tab.li1}
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {tab.li2 ? (
                                        <li className="h6 text-white my-3 py-3 font-extralight leading-snug ">
                                            {tab.li2}
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {tab.li3 ? (
                                        <li className="h6 text-white my-3 py-3 font-extralight leading-snug ">
                                            {tab.li3}
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                </ul>
                            </div>
                        </div>
                    </section>
                ))}
            </div>
        </section>
    );
}

import React from 'react';
import madhurjain from '@partnersAssets/madhur-jain.png';
import './quote.css';

export default function Quote() {
    return (
        <section className="bg-primary-light lg:pt-14 pt-10">
            <div className="container flex flex-wrap">
                <div className="flex-1 min-w-[20rem]">
                    <div className="xxl:p-36 xl:p-28 lg:p-16 pl-6">
                        <img
                            className="w-full h-full"
                            src={madhurjain}
                            alt="Madhur Jain"
                        />
                    </div>
                </div>
                <div className="flex-1 flex flex-col justify-center p-5 relative">
                    <div className="relative">
                        <span className="bg-quote font-bold absolute text-[30vw] -top-[11vw] -left-[6vw] p-0 leading-none">
                            ”
                        </span>
                        <h4 className="para-lines h4 text-gradient-dark font-medium lg:pl-8 relative pr-10">
                            “Never before were people incentivized to grow
                            sustainably. Grab the opportunity to earn while you
                            leave a better environment for future generations.”
                        </h4>
                    </div>

                    <h5 className="h5 mt-4 text-primary">
                        - Madhur Jain, Co-Founder & CEO
                    </h5>
                </div>
            </div>
        </section>
    );
}

import React from 'react';
import './intro.css';
import teamMemberImg from '@aboutAssets/absolutely.jpg';

export default function Intro() {
    return (
        <>
            <section className="bg-primary-dark py-24 md:py-32 lg:py-44 xl:py-52 2xl:py-60">
                <div className="px-5 md:px-10 lg:px-3 md:w-11/12  lg:w-9/12  xl:w-9/12 2xl:w-8/12 max-w-screen-2xl mx-auto grid gap-3 text-gradient  ">
                    <h2 className="h2">Absolutely!</h2>
                    <h5 className="h4 max-w-lg">
                        Our team is going all-in to reverse climate change.
                    </h5>
                </div>
            </section>
            <section className="img-wrapper-bg ">
                <div className="px-5 md:px-10 lg:px-3 md:w-11/12 lg:w-9/12  xl:w-9/12 2xl:w-8/12 max-w-screen-2xl mx-auto grid gap-3 text-gradient">
                    <div className="h-72 md:h-[20rem] lg:h-[24rem] xl:h-[30rem] 2xl:h-[36rem] border-[12px] border-slate-400 border-opacity-50 rounded-lg md:rounded-xl lg:rounded-3xl overflow-hidden">
                        <img
                            src={teamMemberImg}
                            alt="team"
                            className="w-full h-full object-cover object-center"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

import React, { useEffect } from 'react';
import { Banner } from '../../modules/molecules';
import Benefits from './benefits';
import BusinessPartners from './businessPartners';
import CreditQuality from './creditQuality';
import Practices from './practices';
import ProcessCarousel from './processCarousel';
import thumbnail from '@thumbnailsAssets/business.jpg';
import { CDN_URL } from '../../config';
const ForBusiness = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner
                heading="Reimagining climate positive action"
                subHeading="Reverse your GHG emissions with high-quality carbon credits"
                videoLink={CDN_URL + '/for_business.mp4'}
                pb="20"
                thumbnail={thumbnail}
            />
            <CreditQuality />
            <Benefits />
            <ProcessCarousel />
            {/* <BusinessPartners /> */}
            <Practices />
        </>
    );
};
export default ForBusiness;

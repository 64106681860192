import React from 'react';

export default function Stats() {
    return (
        <section className="bg-primary">
            <div className="bg-element-blade-down  lg:h-screen h-fit lg:py-0 py-20 flex items-center">
                <div className="container xl:pl-20">
                    <div className="pb-10 border-b-[1px] border-dotted  border-gray">
                        <h2 className="h2 text-gradient leading-normal max-w-4xl font-medium">
                            Delivering impact with a gamut of sustainable
                            nature-based practices
                        </h2>
                    </div>

                    <div className="grid md:grid-cols-3 max-w-screen-xl gap-5 py-10">
                        <div className="w-60">
                            <h2 className="h2 font-semibold text-yellow">
                                700,000+
                            </h2>
                            <h4 className="h4 font-light text-white">
                                acres of plantation covered
                            </h4>
                        </div>
                        <div className="w-60">
                            <h2 className="h2 font-semibold text-yellow">
                                1,700,000+
                            </h2>
                            <h4 className="h4 font-light text-white">
                                tonnes of CO
                                <sub>2</sub> sequestered
                            </h4>
                        </div>
                        <div className="w-60">
                            <h2 className="h2 font-semibold text-yellow">
                                750,000+
                            </h2>
                            <h4 className="h4 font-light text-white">
                                million liters of water saved
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import './style.css';
import PartnersBackground from '../../../../../assets/ourProjects/partners_background.svg';
function ImplementationPartners({ images, title }) {
    return (
        <section
            className="bg-primary flex flex-col gap-16 sm:gap-[8.1rem] items-center py-16 sm:pb-[16.3rem] sm:pt-[10rem] relative"
            style={{
                backgroundImage: `url(${PartnersBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom left',
                backgroundSize: '65%',
            }}
        >
            <h1 className="h2 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent">
                {title}
            </h1>
            {/* sm:w-[90%] lg:w-[75%] 2xl:w-[63%] */}
            <div className="flex flex-col gap-4 sm:flex-row sm:flex-wrap justify-center sm:gap-[5.37rem]  lg:gap-[10.37rem] w-full 2xl:w-[80rem]">
                {images.map((e, index) => (
                    <div
                        className="flex flex-col gap-2 items-center"
                        key={e.title + index + 'image'}
                    >
                        <div
                            key={e.altText}
                            className={`flex align-middle border-[0.68rem] border-[#3d706d] rounded-3xl ${e.padding}`}
                            style={{
                                backgroundColor: `${e.backgroundColor}`,
                            }}
                        >
                            <img
                                src={e.src}
                                alt={e.altText}
                                className={`rounded-xl ${e.widthStyle} `}
                            />
                        </div>
                        {e.title && (
                            <h2 className="h4 bg-gradient-to-b from-[#FFFFFF] to-[#8FFFE9] bg-clip-text text-transparent text-center">
                                {e.title}
                            </h2>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ImplementationPartners;

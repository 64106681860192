import React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css/pagination';
// import './style.css';

export default function TechMRV({
    title,
    subtitle,
    subtitle_1,
    data,
    videoSrc,
}) {
    return (
        <div className="bg-[#173335] blade-padding-lg blade-padding-bottom-sm">
            <div className="border-b container border-dashed border-white border-opacity-30 pb-4 ">
                <h3 className="text-gradient h2 font-semibold ">{title} </h3>

                <h4 className="text-white h4 max-w-screen-md">{subtitle}</h4>
            </div>

            <div className="mx-auto">
                <div className="!border-none md:flex hidden items-center justify-center overflow-hidden relative">
                    {subtitle_1 ? (
                        <div className="container absolute  top-[1%] left-0 right-0 z-20">
                            <h4 className="text-white  h5 font-light pt-4 ">
                                {subtitle_1}
                            </h4>
                        </div>
                    ) : (
                        ''
                    )}
                    <video
                        autoPlay
                        muted
                        className="w-full object-cover drop-shadow-lg"
                    >
                        <source src={videoSrc} type="video/mp4"></source>
                    </video>
                    <div className="absolute inset-0  h-full w-full border-2 border-[#173335]"></div>
                </div>

                <div className="md:hidden block w-full pt-6 overflow-hidden">
                    <Swiper
                        className="!pb-10"
                        speed={1500}
                        navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                            disabledClass: 'swiper-button-disabled',
                        }}
                        creativeEffect={{
                            limitProgress: 3,
                            prev: {
                                shadow: false,
                                rotate: [0, 0, -45],
                                translate: ['-100%', 0, 0],
                            },
                            next: {
                                rotate: [0, 0, 45],
                                translate: ['100%', 0, 0],
                            },
                        }}
                        modules={[Navigation, Pagination]}
                        pagination
                        spaceBetween={0}
                        effect={'creative'}
                        loop={false}
                        slidesPerView={1}
                    >
                        {data.map((elem, index) => {
                            return (
                                <SwiperSlide key={index + 'swiper'}>
                                    <div className="min-h-full px-14">
                                        <img
                                            src={elem}
                                            alt=""
                                            className="transition-all w-full duration-500"
                                        />
                                    </div>
                                    {index === 0 ? (
                                        ''
                                    ) : (
                                        <div className="text-white absolute h-full top-0 -left-10 ">
                                            <svg
                                                className="w-20 pt-32"
                                                viewBox="0 0 103 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M101.167 11.8C101.44 11.526 101.44 11.083 101.167 10.81L96.7119 6.355C96.4389 6.082 95.9949 6.082 95.7219 6.355C95.4489 6.628 95.4489 7.072 95.7219 7.345L99.6819 11.305L95.7219 15.264C95.4489 15.538 95.4489 15.981 95.7219 16.254C95.9949 16.528 96.4389 16.528 96.7119 16.254L101.167 11.8ZM0.671875 12.005H3.79688V10.605H0.671875V12.005ZM10.0469 12.005H16.2969V10.605H10.0469V12.005ZM22.5469 12.005H28.7969V10.605H22.5469V12.005ZM35.0469 12.005H41.2969V10.605H35.0469V12.005ZM47.5469 12.005H53.7969V10.605H47.5469V12.005ZM60.0469 12.005H66.2969V10.605H60.0469V12.005ZM72.5469 12.005H78.7969V10.605H72.5469V12.005ZM85.0469 12.005H91.2969V10.605H85.0469V12.005ZM97.5469 12.005H100.672V10.605H97.5469V12.005ZM101.662 12.295C102.209 11.748 102.209 10.861 101.662 10.315L92.7519 1.405C92.2059 0.858001 91.3189 0.858001 90.7719 1.405C90.2259 1.952 90.2259 2.838 90.7719 3.385L98.6919 11.305L90.7719 19.224C90.2259 19.771 90.2259 20.657 90.7719 21.204C91.3189 21.751 92.2059 21.751 92.7519 21.204L101.662 12.295ZM0.671875 12.705H3.79688V9.905H0.671875V12.705ZM10.0469 12.705H16.2969V9.905H10.0469V12.705ZM22.5469 12.705H28.7969V9.905H22.5469V12.705ZM35.0469 12.705H41.2969V9.905H35.0469V12.705ZM47.5469 12.705H53.7969V9.905H47.5469V12.705ZM60.0469 12.705H66.2969V9.905H60.0469V12.705ZM72.5469 12.705H78.7969V9.905H72.5469V12.705ZM85.0469 12.705H91.2969V9.905H85.0469V12.705ZM97.5469 12.705H100.672V9.905H97.5469V12.705Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

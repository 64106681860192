import React from 'react';
import './timeline.css';

export default function MobileView({ defaultTab = 0, data }) {
    const [activeTab, setActiveTab] = React.useState(defaultTab);

    React.useEffect(() => {
        const tabs = document.querySelectorAll('[role="tab"]');
        const arrowKeyHandler = (event) => {
            let nextTab;
            switch (event.key) {
                case 'ArrowRight':
                    nextTab = activeTab >= tabs.length - 1 ? 0 : activeTab + 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                case 'ArrowLeft':
                    nextTab = activeTab <= 0 ? tabs.length - 1 : activeTab - 1;
                    setActiveTab(nextTab);
                    tabs[nextTab].focus();
                    break;
                default: {
                }
            }
        };
        document.addEventListener('keyup', arrowKeyHandler);
        return () => {
            document.removeEventListener('keyup', arrowKeyHandler);
        };
    }, [activeTab]);

    return (
        <section className="pb-32">
            <div className="w-[100%] mx-auto">
                <div className="timeline-card flex blade-margin-xs">
                    <div
                        className="h-full flex"
                        role="tablist"
                        aria-label="Top Footballers"
                    >
                        <div className="flex items-start w-16 mt-4">
                            <div
                                className={`timeline-status bg-primarylight h-full overflow-visible w-[1px] rounded-lg relative`}
                            >
                                {/* <span
                  className="bg-cyan rounded-lg transition-all duration-500 overflow-visible"
                  style={{ height: activeTab * 12.8 + 5 + "%" }}
                ></span> */}
                            </div>
                        </div>

                        <div className="flex flex-col">
                            {data.map((tab, index) => (
                                <section key={index} className="mt-4">
                                    <div className="tab-panel-timeline h-full bg-primary-light flex flex-col justify-between items-center">
                                        <div className="bg-primary-light w-[100%] lg:py-20 py-5 xl:px-20 px-5">
                                            <h2 className="text-primary h2 font-semibold">
                                                {tab.title}
                                            </h2>
                                            <h2 className="text-primary h3 font-medium">
                                                {tab.heading}
                                            </h2>

                                            <ul className="mt-2">
                                                <li className="h6 py-3 font-light">
                                                    {tab.li1}
                                                </li>
                                                {tab.li2 ? (
                                                    <li className="h6 py-3 font-light">
                                                        {tab.li2}
                                                    </li>
                                                ) : (
                                                    ''
                                                )}
                                                {tab.li3 ? (
                                                    <li className="h6 py-3 font-light">
                                                        {tab.li3}
                                                    </li>
                                                ) : (
                                                    ''
                                                )}
                                                <li className="h6 py-3 font-light">
                                                    {tab.li4}
                                                    {tab.li_1 ? (
                                                        <ul className="list-disc pl-6">
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_1}
                                                            </li>
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_2}
                                                            </li>
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_3}
                                                            </li>
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_4}
                                                            </li>
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_5}
                                                            </li>
                                                            <li className="h6 py-1 font-light">
                                                                {tab.li_6}
                                                            </li>
                                                        </ul>
                                                    ) : (
                                                        ''
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </section>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import CountUp from 'react-countup';
import './style.css';
function ProjectImpact({ title, contents }) {
    return (
        <section className=" bg-[#001A18] projectimpact-wrapper">
            <div className="blade-padding-sm">
                <h2 className="h2 text-gradient text-center lg:w-[50%] w-11/12 2xl:w-[35%] mx-auto leading-tight py-5">
                    {title}
                </h2>
                <div className="blade-padding-xs px-3">
                    <div className="flex flex-nowrap px-3 py-14 max-w-md md:flex-row flex-col items-center  justify-around md:max-w-screen-2xl mx-auto  md:w-11/12  cards-wrapper rounded-3xl gap-12 sm:gap-0">
                        {contents.map((e, index) => (
                            <figure key={`${index}_${e.altText}`}>
                                <div className="flex flex-col gap-6 items-center">
                                    <div className="px-12">
                                        <img
                                            src={e.imageUrl}
                                            alt={e.altText}
                                            className="w-24 h-24"
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        {e.start && e.end ? (
                                            <span className="flex items-baseline gap-2 justify-center">
                                                <CountUp
                                                    className="h5 text-[#F9AC00] font-bold"
                                                    start={e.start}
                                                    end={e.end}
                                                    suffix={''}
                                                    duration={6}
                                                    separator=","
                                                />
                                                {e.unit && (
                                                    <h6 className="h6 text-[#F9AC00] font-bold">
                                                        {e.unit}
                                                    </h6>
                                                )}
                                            </span>
                                        ) : (
                                            <span className="flex items-baseline gap-2 justify-center">
                                                <h5 className="h5 text-[#F9AC00] font-bold">
                                                    {e.title}
                                                </h5>
                                            </span>
                                        )}
                                        {e.description && (
                                            <h3 className="h6 text-white text-center">
                                                {e.description}
                                            </h3>
                                        )}
                                    </div>
                                </div>
                            </figure>
                        ))}
                    </div>
                </div>
                <div className="blade-padding-lg mt-20"></div>
            </div>
        </section>
    );
}

export default ProjectImpact;
